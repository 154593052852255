@import 'colors';
@import 'components';

.container {
  background-color: $white2;
  min-height: 100%;
  height: 1px;
  display: flex;
  flex-direction: column;
  > header {
    flex-shrink: 0;
    @include panel($white);
    h3 {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: $black;
    }
    padding: 30px;
    background-color: $white;
    margin: 0;
    z-index: 3;
    position: relative;
  }
}

.adLogoDetail {
  margin: 20px 30px;
  color: $dark;
  &.clickable img {
    cursor: pointer;
  }
}

.agencyDetail {
  background-color: $white;
  box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
}

.topArea {
  height: 50px;
  border-bottom: 1px solid $super-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .title {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
    padding: 20px 0px 11px 30px;
  }
  .buttonArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 30px;
    flex-shrink: 0;
    & > a {
      cursor: pointer;
      margin-left: 30px;
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: $theme1;
    }
    a:hover {
      color: $theme2;
    }
  }
}

.titleBottomLine {
  width: 100%;
  height: 2px;
  border: 1px solid $super-light;
  background-color: $white;
}

.infoArea {
  :global {
    .nav {
      padding-left: 20px;
      background-color: $white;
      flex-shrink: 0;
      z-index: 1;
      border-bottom: 1px solid $super-light;
    }
  }
}

.memberList {
  box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
  margin-top: 28px;
}
