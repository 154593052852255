@import "colors.scss";
@import "fonts.scss";

.limitationSetting {
    background: $white;
    padding: 40px 69px 40px 30px;
    display: flex;
    flex-direction: column;
    :global(.errorTip) {
        font-size: 12px;
        position: absolute;
        left: 170px;
        color: $danger;
    }
}

.titleContainer {
    display: flex;
    justify-content: space-between;
}

.title {
    display: flex;
    align-items: center;
    color: $dark;
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    &::before {
        width: 10px;
        height: 20px;
        margin-right: 6px;
        background: $success;
        content: '';
    }
}

.include::before {
    background: $success;
}

.exclude::before {
    background: $danger;
}

.preferred::before {
    background: $info;
}

.nonPreferred::before {
    background: $warning;
}

.editTitle {
    width: max-content;
    height: 14px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: right;
    color: $theme1;
    cursor: pointer;
    margin-right: 31px;
    &:hover {
        color: $theme2;
    }
}

.settingContainer {
    margin-top: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
}

.hide {
    display: none;
}

.limitationSet {
    width: 100%;
    margin: 0;
    :global(.form-group.row) {
        margin: 0;
        margin-bottom: 25px;
        align-items: center;
        >:global(.form-label) {
            flex-basis: 170px;
            margin: 0px;
            padding-right: 30px;
        }
    }
    .withPostComment {
        :global(.form-group.row) {
            margin-bottom: 0;
        }
    }
}

[class$="LimitationPrecomment"] {
    position: absolute;
    font-size: 12px;
    top: -20px;
    left: 170px;
    background-color: initial;
    &:global {
        &.include {
            color: $success;
        }
        &.exclude {
            color: $danger;
        }
        &.preferred {
            color: #99d4e9;
        }
        &.nonPreferred {
            color: $warning;
        }
    }
}

[class$="LimitationPostcomment"] {
    font-size: 12px;
    color: $light;
    @include base-font;
    display: block;
    margin-bottom: 7px;
    margin-left: 170px;
    :global(.count) {
        color: $dark;
    };
}

.inventoryActive {
    margin-left: 40px;
    [class$="LimitationPrecomment"], [class$="LimitationPostcomment"], :global(.errorTip) {
        left: 0;
        margin-left: 0;
    }
    :global(.form-group.row) {
        >:global(.form-label) {
            flex-basis: 100%;
            text-align: left;
            margin-bottom: 6px;
        }
    }
}
.restrictHeight {
    max-height: 556px;
    overflow-y: auto;
}
