@import 'fonts.scss';
@import 'colors.scss';

.creativeReview {
  white-space: nowrap;
  display: flex;
  align-items: center;
  @include base-font;
  font-size: 14px;
  line-height: 1.57;
  color: $dark;
  justify-self: center;
  position: relative;
  .approvalContent {
    width: max-content;
    cursor: pointer;
    font-size: 12px;
    color: $white;
    height: 18px;
    border-radius: 12px;
    line-height: 18px;
    box-sizing: content-box;
    &.marginLeft {
      margin-left: 10px;
    }
    padding-left: 10px;
    padding-right: 10px;
    &.none {
      @include base-font;
      color: $dark;
      line-height: 22px;
    }
    &.new {
      background-color: $dark;
    }
    &.pending {
      background-color: $warning;
    }
    &.approved {
      background-color: $success;
    }    
    &.denied {
      background-color: $danger;
    }
    &.fixed {
      background-color: $black;
    }
    &.incompleted {
      background-color: $warning;
    }
    &.deleted {
      background-color: $danger;
    }
  }
  
  .reviewBtn {
    margin-left: 10px;
    color: $dark;
    position: absolute;
    right: -15px;
  }

  .stateDetailInfo {
    .section {
      margin-bottom: 5px;
      word-break: break-all;
    }
  
    .warningContent {
      .warning {
        margin-left: 0px;
        margin-right: 7px;
        position: relative;
        right: auto;
      }
      .warningHint {
        padding-left: 20px;
      }
    }
  }
  .warning {
    color: #fad201;
    margin-left: 5px;
    font-size: 12px;
    position: absolute;
    right: -15px;
  }
}