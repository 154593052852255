@import 'colors.scss';

.goToDisabled {
  cursor: not-allowed !important;
}

.urlInputGroup {
  .withoutBorder {
    border: none !important;
  }

  &:global(.input-group) {
    input {
      width: 424px;
    }
  }
  &.error {
    input {
      border-radius: 2px;
      border: 1px solid $danger !important;
    }
  }
  :global {
    .input-group {
      .form-control {
        height: auto;
      }
    }
  }
  :global(.input-group-text) {
    &.disabled {
      border: none;
      z-index: 1;
      border-right: 1px solid $super-light;
      color: $super-light;
      background-color: $light-pink;
    } 
  }
  :global(.input-group-append) {
    span {
      width: 36px;
      height: 36px;
      padding: 0;
      border: none !important;
      cursor: pointer;
      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > svg {
          color: $dark;
        }
      }
    }
  }
}