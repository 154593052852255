.advertiserMemberForm {
  margin-top: 30px;
  :global(.row) {
    margin-left: 0;
    margin-right: 0;
  }
  .roleSelect {
    width: 460px;
  }
  input[name="email"] {
    width: 460px;
  }
}