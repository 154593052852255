@import 'fonts';

.goSegmentStatusContainer {
  white-space: nowrap;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.hasTip {
    cursor: pointer;
  }

  .goSegmentType {
    display: inline-block;
    width: 60px;
    text-align: right;
    margin-right: 10px;
  }
  .goSegmentStatus {
    white-space: nowrap;
    color: $white;
    height: 18px;
    border-radius: 12px;
    line-height: 18px;
    box-sizing: content-box;
    padding-left: 10px;
    padding-right: 10px;
    background-color: $dark;
    &.warning {
      background-color: $warning;
    }
    &.success {
      background-color: $success;
    }    
    &.black {
      background-color: $black;
    }
  }
}