@import 'components.scss';

.fbAdSetMainStep {
  height: 100%;
  overflow: auto;

  @include tabs;

  .label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: $dark;
  }
  .tabs {
    padding-top: 27px;
  }
  .buttonArea {
    margin: 60px 0 120px 30px;
    button {
      margin-right: 20px;
    }
  }
  fieldset {
    margin-top: 40px;
    padding-left: 0;
    padding-right: 0;
    legend>span {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: $black;
      margin-left: 30px;
    }
    .component {
      background-color: $white;
      box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
      padding: 40px 30px;
    }
  }

  :global(.form-check) {
    padding-left: 0;
  }
  
  .bidControl {
    margin-top: 5px;
    .bidAmount {
      margin-top: 10px;
    }
    > :global(.row) {
      margin-bottom: 2px;
    }
  }

  .bidStrategyHint {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
  }
  .spentLimitLabel {
    @include base-font;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }
  .maxFrequency, .intervalDays {
    width: 70px;
    &.error {
      border-color: $danger;
    }
  }
  .frequencyControl {
    :global(.errorTip) {
      margin-top: 5px;
    }
  }
  .frequencyControl, .lifetimeBudgetError {
    :global(.errorTip), a {
      min-height: 14px;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      width: 100%;
      display: block;
    }
    a {
      margin-top: 2px;
    }
    :global(.errorTip) {
      color: $danger;
    }
  }
  .frequencyControl {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: $dark;
  }

  .ageSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      width: 75px;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}