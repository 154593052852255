@import 'colors.scss';

.chartContainer {
  position: relative;
  .arrowContainer {
    position: absolute;
    left: 60px;
    top: 20px;
    width: 2px;
  }
  .arrow {
    position: relative;
    height: 100%;
    border-left: solid 2px $danger;
  }
  .arrow:after,
  .arrow:before {
    height: 15px;
    width: 2px;
    background-color: $danger;
    content: '';
    display: block;
    position: absolute;
    top: calc(100% - 15px);
    right: 0;
  }

  .arrow:before {
    transform: rotate(40deg);
    transform-origin: bottom left;
  }

  .arrow:after {
    transform: rotate(-40deg);
    transform-origin: bottom right;
  }
}