@import 'fonts.scss';

.sourceDetails {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  background-color: $white;
  border-bottom: solid 1px #dee2e6;
  .triggerSourceDetailsBtn {
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.18;
    color: $black;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
  .detailsSection {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .detail {
      @include base-font;
      display: grid;
      grid-template-columns: auto 1fr;
      margin: 5px 0px;
      .title {
        width: 180px;
        font-size: 12px;
        font-weight: normal;
        color: $light;
      }
      .content {
        font-size: 14px;
        font-weight: normal;
        color: $dark;
      }
    }
  }
}