@import 'fonts.scss';

.withAddIcon {
  display: flex;
  align-items: center;

  svg {
    font-size: 14px;
    @include base-font;
    color: $theme2;
    margin-right: 6px;
  }
}