@import 'fonts.scss';

.ppsMacroInstruction {
  padding: 30px;
  padding-right: 30px;
  position: relative;
  .closeBtn {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
  .sessionTitle {
    @include base-font;
    padding: 10px 0;
    padding-top: 35px;
    font-weight: 700;
    font-size: 14px;
    color: $dark;
  }
  .macroTable {
    width: 640px;
  }
  table, th, td {
    border: 1px solid $super-light;
    @include base-font;
    font-size: 14px;
    color: $dark;
  }
  th, td {
    padding: 5px;
  }
  tbody > tr:nth-child(2n - 1) {
    background-color: $white3;
  }
  th {
    font-weight: 700;
  }
}