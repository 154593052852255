@import '../../fonts.scss';
@import '../../colors.scss';

.draftSummaryStateContent {
  .header {
    display: flex;
    height: 100px;
    padding: 40px 30px;
    background-color: $white;
    
    .title {
      @include base-font;
      font-size: 17px;
      font-weight: normal;
      line-height: 1.18;
      color: $dark;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 10px;
        height: 20px;
        background-color: $light;
        margin-right: 6px;
      }
    }
  }
  .description {
    @include base-font;
    height: 20px;
    margin-left: 40px;
    font-size: 17px;
    font-weight: normal;
    line-height: 1.18;
    color: $dark;
    display: flex;
    align-items: center;
  }
  .confirmations {
    display: flex;
    flex-direction: column;
    padding: 10px 60px;
    .row {
      display: grid;
      grid-template-columns: repeat(4, 1fr) 0.5fr 1.5fr;
      align-items: center;
      border-bottom: solid 1px #dee2e6;
      .cell {
        @include base-font;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        max-width: 200px;
        &.status {
          max-width: 450px;
          margin-left: auto;
          justify-content: flex-end;
          text-align: right;
          word-break: break-word;
        }
        &.passed {
          color: $success;
        }
        &.danger {
          color: $danger;
        }
      }
      .title {
        margin: 6px 0px;
        color: $black;
      }
      .content {
        margin: 30px 0px;
        color: $dark;
        &.status {
          margin: 10px 0px;
          margin-left: auto;
        }
      }
    }
  }
  .hints {
    @include base-font;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
    color: $light;
    padding: 5px 60px;
  }
}