@import 'fonts.scss';
@import 'colors.scss';
@import 'components.scss';

.contentArea {
  td {
    .hoverBtns {
      visibility: hidden;
      cursor: pointer;
      color: $theme2;
    }
  }
  tr:hover {
    .hoverBtns {
      visibility: visible;
    }
  }
}