@import 'fonts.scss';

.setupStep {
  min-width: min-content;
  height: 100%;
  overflow: auto;
  
  .buttonArea {
    margin: 30px 0 30px 30px;
    button {
      margin-right: 20px;
    }
  }
}