@import 'fonts.scss';

.layoutIdWarning {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  padding-top: 5px;
  color: $warning;
}

.macroInstructionBtn {
  font-size: 12px;
  line-height: 1.5;
  padding-top: 7px;
  svg {
    font-size: 14px;
    margin-left: 5px;
  }
  color: $primary1;
  cursor: pointer;
}

.imageFormInputContainer {
  position: relative;
  width: max-content;
  .imageInput {
    width: max-content;
    height: max-content;
    min-width: 200px;
    border: none !important;
  }
  button {
    margin-top: 10px;
  }
}