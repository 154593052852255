@import 'fonts.scss';
@import 'components.scss';

.goSegmentList {
  $topAreaHeight: 77px;
  height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $white2;
    > div {
      height: 100%;
    }
    
    @include tabs;
    :global(.nav) {
      padding-top: 30px;
      height: 72px;
    }
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  thead th,
  td {
    &.editBtns {
      width: 0;
      min-width: 0;
      max-width: 0;
      padding: 0px;
    }
  }
  th:first-child,
  td:first-child {
    padding-left: 64px;
  }
  .floatingEditArea {
    display: none;
  }
  .nameCell {
    display: flex;
    flex-direction: column;
    .name {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
  tr:hover {
    .floatingEditArea {
      display: flex;
    }
    td {
      background-color: $white2;
    }
  }
  .filterArea {
    a {
      margin-left: 30px;
    }
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    height: $topAreaHeight;
    background-color: $white;
    position: relative;
    :global(.search-bar) {
      width: 370px;
      margin: 0px;
      position: absolute;
      right: 30px;
    }
    .createBtn {
      color: $white;
    }
  }
  .segmentId, .description, .detail {
    min-width: 250px;
    max-width: 360px;
  }
  .audienceCount,
  .duration,
  .type,
  .status,
  .max_alive_date {
    white-space: nowrap;
  }
  .status {
    > div {
      > div:nth-child(2) {
        width: 125px;
        margin-top: 5px;
      }
    }
    .danger {
      color: $danger;
      font-weight: bold;
    }
  }
}

.tableContainer {
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: 1px solid $super-light;
  :global(.table) {
    margin-bottom: 0;
  }
}
