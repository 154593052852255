.productGroupTips {
  margin-bottom: 3px;
  ul {
    padding: 0px 0px 0px 20px;
    margin: 0px;
    list-style-type: decimal;
    li {
      overflow-wrap: anywhere;
    }
  }
}
