.selectInput {
  display: flex;
  flex-direction: row;
  .selection {
    width: 170px;
    min-height: 35px;
    > div:first-of-type {
      min-height: 35px;
      border-radius: 4px 0px 0px 4px;
      border-right-width: 0px;
      &:hover, &:global(.react-select__control--is-focused) {
        border-right-width: 1px;
      }
    }
  }
  > input {
    width: 468px;
    height: 35px !important;
    box-sizing: border-box;
    border-radius: 0px 4px 4px 0px;
  }
}