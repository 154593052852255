@import '../../../colors.scss';
@import '../../../fonts.scss';

.creativeSetupFlowPage {
  height: 100%;
  display: flex;
  .topArea {
    height: 118px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    z-index: 3;
    position: relative;
  }
  .contentContainer {
    height: calc(100% - 118px);
    background-color: $white3;
    overflow: auto;
  }
  .appVersion {
    background-color: $theme2;
    width: 100%;
    @include base-font;
    font-size: 8px;
    font-weight: 500;
    line-height: 14px;
    color: $white;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}