@import 'colors.scss';
.topArea {
  height: 118px;
  box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
  background-color: $white;
  z-index: 3;
  position: relative;
}
.contentContainer {
  height: calc(100% - 118px);
  background-color: $white3;
  overflow: auto;
  padding-top: 30px;
}