@import "fonts.scss";

.limitationSet {
    position: relative;
    :global {
        .react-tagsinput-input {
            display: none;
        }
    }
    
    .tags {
        flex: 1;
    }
    
    .hasError {
        :global(.react-tagsinput) {
            border-color: $danger;
        }
    }
    
    .emptySpace {
        min-width: 32px;
        max-width: 32px;
    }

    :global(.errorTip) {
       position: relative; 
    }

    &.unicornkeyword1 {
        :global(.row) {
            margin-bottom: 0;
        }
    }

    &.unicornkeyword2 {
        [class$="LimitationPrecomment"] {
            position: relative;
            top: 0px;
        }
    }
    .clearBtn {
        position: absolute;
        font-size: 16px;
        @include base-font;
        color: $dark;
        top: 9px;
        right: 43px;
        cursor: pointer;
    }
}

.unicornkeyword1 + div {
    margin-top: 15px;
}

.unicornkeyword1 + .unicornkeyword2 {
    margin-top: 0px;
}