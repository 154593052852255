@import 'colors.scss';
@import 'fonts.scss';

.setupStep {
  min-width: min-content;
  height: 100%;
  overflow: auto;
  :global(.nav-item.nav-link.active) {
    background-color: $white3;
    color: $theme1;
  }

  :global(.nav-item.nav-link) {
    height: 38px;
    color: $dark;
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }

  :global(.nav-item.nav-link:hover:not(.active)) {
    box-sizing: border-box;
    border-bottom-color: transparent;
    height: 100%;
  }

  :global(.nav-tabs) {
    padding-top: 27px;
    padding-left: 28px;
    height: 64px;
  }

  :global(.tab-content fieldset) {
    margin-top: 40px;
    padding-left: 0;
    padding-right: 0;
  }

  .buttonArea {
    margin: 60px 0 120px 30px;
    button {
      margin-right: 20px;
    }
  }
}