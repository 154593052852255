@import 'fonts.scss';
@import 'colors.scss';
@import 'components.scss';

.accountsHome {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 1 1 auto;

  .topPanel {
    @include panel($white);
    h3 {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: $black;
    }
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 30px;
  }

  .operationPanel {
    flex-shrink: 0;
    background-color: $white;
    border-bottom: 1px solid $super-light;
    position: relative;
    display: flex;
    align-items: center;
    height: 66px;
    a {
      margin-left: 30px;
      @include createButton;
    }
    :global(.search-bar) {
      width: 280px;
      margin: 0px;
      position: absolute;
      right: 40px;
    }
    .agencyOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px; 
      .title {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $dark;
        margin-right: 10px;
      }
    }
  }

  .listContainer {
    @include list-container;
    td, th {
      &:first-child {
        padding-left: 64px;
      }
      vertical-align: middle;
    }
  }
}
