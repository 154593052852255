@import 'fonts.scss';

.fileFormInput {
  > div {
    border: dashed 1px $super-light;
    background-color: $white;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & + span {
    @include base-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: $light;
    &:global(.errorTip) {
      color: $danger;
      height: auto;
    }
  }
}

.showErrorBorder {
  border: none !important;
  > div {
    border-color: $danger !important;
  }
}


.showSolidBorder {
  > div {
    border: 1px solid $super-light;
  }

  &.showErrorBorder {
    > div {
      border: 1px solid $danger;
    }
  }
}