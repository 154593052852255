@import '../../fonts.scss';
@import '../../colors.scss';
.creativeCardContainer {
    position: relative;
}

.hint {
    display: none;
}

.disableTips {
    display: block;
    position: absolute;
    z-index: 10;
    background: $theme1;
    color: $white;
    top: 144px;
    width: 236px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.creativeCard {
    display: flex;
    flex-direction: column;
    width: 236px;
    min-width: 236px;
    min-height: 315px;
    overflow: hidden;
    background-color: $white;
    border: 1px solid $super-light;
    margin-right: 30px;
    margin-bottom: 30px;
    position: relative;
    &.disable:after {
        content: "";
        box-shadow: inset 0 0 0 100em rgba(255, 255, 255, 0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    &.selected {
        border: 2px solid $theme1;
    }
    .checkbox {
        margin: 7px;
        margin-bottom: 10px;
        input {
            display: none;
        }
        input+label::before,
        input:hover+label::before {
            width: 21px;
            height: 21px;
            border-radius: 3px;
            border: 3px solid $dark;
            background-color: $white;
        }
        input:checked+label::before,
        input:indeterminate+label::before {
            width: 21px;
            height: 21px;
            background-color: $theme1;
        }
        input:checked+label::after,
        input:indeterminate+label::after {
            top: 12px;
            left: 5px;
        }
    }
    .previewArea {
        width: fit-content;
        max-width: 216px;
        height: 153px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 15px;
    }
    .name {
        flex-grow: 1;
        width: 226px;
        @include base-font;
        font-size: 14px;
        line-height: 1.57;
        color: $black;
        margin: auto;
        margin-bottom: 4px;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }
    .landingPageUrl {
        @include base-font;
        font-size: 12px;
        line-height: 1.24;
        color: $dark;
        border-top: 1px solid $super-light;
        position: relative;
        padding-left: 5px;
        display: flex;
        padding-bottom: 1px;
        min-height: 18px;
        .url {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 210px;
            word-break: break-all;
            flex-grow: 1;
        }
        .btn {
            cursor: pointer;
            display: flex;
            align-items: center;
        }
    }
    .footer {
        height: 17px;
        @include base-font;
        font-size: 12px;
        line-height: 1.17;
        color: $dark;
        display: flex;
        flex-direction: row;
        .id {
            flex-basis: 40%;
            border-top: 1px solid $super-light;
            padding-left: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .ctime {
            flex-basis: 60%;
            border-top: 1px solid $super-light;
            border-left: 1px solid $super-light;
            padding-left: 5px;
        }
    }
}