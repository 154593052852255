@import 'fonts.scss';

.options {
  flex: 1;
  max-height: 500px;
  margin-right: 40px;
  > div {
    width: auto;
    min-width: 400px;
  }
  textarea {
    height: 96px;
  }
}

.selectedFilters {
  flex: 1;
  
  :global {
    .form-group.row {
      margin: 0px;
      margin-bottom: 25px;
      align-items: center;
      >.form-label {
          flex-basis: 100%;
          text-align: left;
          margin-bottom: 6px;
          padding-right: 30px;
      }
    }
    .groupValues {
      .form-group.row {
        flex-wrap: nowrap;
        margin-bottom: 0;
        >.form-label {
          margin: 0;
          flex-basis: 0;
          width: 0;
          padding: 0;
        }
      }
      .react-tagsinput {
        min-height: 500px;
        max-height: 500px;
        overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
          width: 6px !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border: none !important;
        }
      
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba(97, 95, 103, 0.5) !important;
          border: 1px solid #ffffff !important;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgb(97, 95, 103) !important;
        }
      }
      .react-tagsinput--focused {
        border-color: $super-light
      }
    }
  }
}

.copyHintLabel {
  display: block;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: $theme1;
  margin: 0px;
  margin-top: 2px;
  width: fit-content;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.otherField {
  width: 60vw;
}

textarea.otherField {
  height: 100px;
}

.sectionOnlyTitle {
  padding: 60px 30px 40px 30px !important;
}

.collapseButton {
  cursor: pointer;
  color: $theme1;
  font-size: 14px;
  position: absolute;
  right: 65px;
  top: 40px;
}
