@import 'fonts.scss';
@import 'colors.scss';

.codePage {
  padding-top: 30px;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  :global(.col-sm-10) {
    label {
      text-align: left;
      color: $dark;
      font-size: 14px;
      @include base-font;
      padding-left: 0;
      max-width: 670px;
      span {
        cursor: pointer;
        color: $primary1;
      }
    }
    textarea {
      color: $dark;
      font-size: 14px;
      @include base-font;
      max-width: 670px;
      min-width: 670px;
      height: 310px;
      padding: 10px;
      &:read-only {
        background-color: $white;
        border: solid 1px $super-light;
        &:hover {
          border: 1px solid $dark;
        }
      }
    }
    .hint {
      display: block;
      font-size: 12px;
      font-weight: 500;
      @include base-font;
      line-height: 1.17;
      color: #98969d;
      margin-top: 2px;
      width: 100%;
    }
  }
  .code {
    flex-grow: 1;
  }
}