@import 'fonts.scss';

.previewItem {
  width: min-content;
  .previewArea {
    width: fit-content;
    border: 1px solid $super-light;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .itemInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
}
.itemDetail {
  :global(.modal-content),
  :global(.modal-dialog) {
    width: fit-content;
    height: fit-content;
    max-width: 100%;
  }
  :global(.modal-body) {
    padding: 0px;
    display: flex;
  }
}
