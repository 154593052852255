@import 'fonts.scss';

.audioInputContainer {
  width: 100%;
  height: 100%;

  .filePreview {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fileInput {
    display: none;
  }

  .previewArea {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    > .fileIcon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        font-size: 75px;
        color: $dark;
      }
    }
    .mask,
    .filePlaceholder {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mask {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .filePlaceholder {
      svg,
      .hint {
        color: $white2;
      }
    }
    .closeBtnContainer {
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 8px;
      margin: auto;
      width: 32px;
      height: 32px;
      box-shadow: 0 2px 5px 0 rgba(97, 95, 103, 0.5);
      background-color: $white;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .closeBtn {
        width: 16px;
        height: 21px;
        @include base-font;
        font-size: 21px;
        color: $dark;
      }
    }
  }

  .filePlaceholder {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > svg {
      font-size: 50px;
      color: $light;
    }
    .hint {
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      text-align: center;
      color: $dark;
      margin-top: 10px;
    }
  }
}
