@import 'fonts';

.contactUs {
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  background-color: $white2;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 606px;
    margin-top: 60px;
    margin-bottom: 60px;
    img {
      margin-bottom: 30px;
    }
    p {
      @include base-font;
      font-size: 14px;
      line-height: 1.57;
      color: $black;
      margin-bottom: 40px;
    }
  }
}