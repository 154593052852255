@import 'colors.scss';
@import 'fonts.scss';

.nativePreview {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 30px;
    width: fit-content;
    height: fit-content;
  }
}

.nativeBannerPreview {
  min-width: 1000px;
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 30px;
    margin-bottom: 13px;
  }
}

.sepLine {
  height: 1px;
  border-top: solid 1px $super-light;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title {
  @include base-font;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  color: $dark;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  
  .titlePrefix {
    width: 6px;
    height: 20px;
    background-color: $light;
    margin-right: 6px;
  }
}
