@import 'fonts.scss';

.page {
  overflow: auto;
  height: 100%;
  min-height: 100%;
  background-color: $white2;
  display: flex;
  flex-direction: column;
  .topArea {
    background-color: $white;
    min-height: 84px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    &.topAreaWithPadding {
      padding: 30px;
    }
    &.topAreaEndWithShadow {
      box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    }
    &.spaceBetweenTopAreaAndContent {
      margin-bottom: 30px;
    }
  }
  .mainArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    > div {
      flex-grow: 1;
    }
  }
}

.pageSection {
  position: relative;

  h2 {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 41px 0 16px 30px;
  }

  .sectionContent {
    background-color: $white;
    padding: 40px 30px;
  }

  &.withShadow {
    .sectionContent {
      box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
    }
  }

  .titleArea {
    display: flex;
    align-items: center;
    svg {
      margin-top: 1px;
      margin-left: 7px;
      color: $primary1;
    }
  }

  &.inlineTitle {
    margin-top: 5px;

    .titleArea {
      position: absolute;
      left: 30px;
      top: 40px;
    }

    h2 {
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.18;
      letter-spacing: normal;
      margin: 0px;
      &::before {
        width: 10px;
        height: 20px;
        margin-right: 6px;
        background: $dark;
        content: '';
      }
    }

    .sectionContent {
      padding: 100px 30px 40px 30px;
    }
  }
}

.buttonArea {
  position: relative;
  margin: 30px 0 30px 30px;
  background-color: $white2;

  button {
    margin-right: 20px;
  }
}

:global {
  [id^="productGroupTitleTip-"] {
    max-width: 242px;
  }
}