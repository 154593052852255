@import 'colors.scss';

.checkbox {
  height: 36px;
  display: flex;
  align-items: center;
  label {
    color: $dark;
    margin-right: 8px;
  }
  input[type=checkbox]+label:before {
    margin-top: -2px;
  }
}