@import 'fonts.scss';

.doctorTableExtender {
  min-height: 400px;
  display: flex;
  align-items: center;
  position: relative;
  .spaceList {
    height: 350px;
  }
  .reasonContainer {
    border: 1px solid $super-light;
    border-left: none;
    height: 350px;
    flex-grow: 1;
    margin-right: 60px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px 5%;
    .reasonTitle {
      @include base-font;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      color: $black;
      align-self: center;
      margin-bottom: 20px;
    }
    .reasonContent {
      width: 100%;
      height: calc(100% - 21px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .reasonPlaceholder {
      @include base-font;
      color: $light;
    }
  }
}