@import 'colors.scss';
@import 'fonts.scss';

.root {
  .content {
    background-color: $white;
    width: 100%;
    margin-top: 39px;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    :global(.col-sm-3) {
      label {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        text-align: right;
        color: $black;
      }
    }
  }
  .buttonArea {
    margin: 60px 0px 80px 30px;
  }
  .error {
    :global(.react-select__control) {
      border-color: $danger;
    }
  }
  .wording {
    height: 14px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $danger;
    width: 100%;
    display: block;
    margin-top: 2px;
  }
}
