@import "../../colors.scss";
@import "../../fonts.scss";

.summaryDetail {

  margin: 10px 0px;
  
  .title {
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: $dark;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 10px 0;
    
    .titlePrefix {
      width: 6px;
      height: 20px;
      background-color: $light;
      margin-right: 6px;

      &.green {
        background-color: $success;
      }
      &.blue {
        background-color: #99d4e9;
      }
      &.yellow {
        background-color: $warning;
      }
      &.red {
        background-color: $danger;
      }
      &.dark {
        background-color: $dark;
      }
    }
  }

  .details {
    .detailRow {
      display: flex;
      flex-direction: row;
      padding: 6px 0px 6px 0px;
      min-height: 34px;

      .label {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $black;
        width: 185px;
        text-align: right;
        padding-right: 30px;
        min-width: 185px;
      }
  
      .value {
        @include base-font;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        color: $dark;    
        white-space: pre-line;
        word-break: break-all;
      }
      .area {
        @include base-font;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        color: $dark;    
        white-space: pre;
        padding: 0;
        overflow: auto;
      }
      
      .hint {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #98969d;
        margin-top: 2px;
        width: 100%;
        grid-row: 2 / 3;
        grid-column: 1 / 3;
      }
      
      .green {
        color: $success;
      }
      .blue {
        color: #99d4e9;
      }
      .yellow {
        color: $warning;
      }
      .red {
        color: $danger;
      }
      .dark {
        color: $dark;
      }
    }
  }
}