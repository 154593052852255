@import 'fonts.scss';
.loadingCircle {
  border-radius: 50%;
  border: 2px solid $dark;
  animation-name: roller;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  border-left-color: transparent;
}

@keyframes roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}