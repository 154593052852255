@import 'colors.scss';

.container {
  width: 100%;
  height: 100%;
  position: relative;
  &.isCustom {
    position: initial;
  }

  .switchButton {
    position: absolute;
    bottom: 8px;
    right: 12px;
    height: 24px;
    padding: 3px 30px;
    z-index: 2;
  }

  .componentArea {
    width: 100%;
    height: 100%;
    position: absolute;
  
    > div {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .expandComponentArea {
    width: calc(100% - 160px);
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 1;
    transition: width 0.3s ease;
    border: solid 1px $super-light;
    border-left: none;
    background-color: $white;
    margin-top: -1.25px;

    .filterContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 34px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
    }
  }
}
