@import 'fonts.scss';
@import 'colors.scss';

.filterBtn {
  display: inline-block;
}
.filterBtn {
  @include base-font;
  font-size: 14px;
  color: $dark;
  cursor: pointer;
  svg {
    margin-right: 7px;
  }
  margin-left: 22px;
  margin-right: 5px;
  flex-shrink: 0;
}
.filterTags {
  flex-grow: 1;
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  > div {
    flex-shrink: 0;
  }
  :global(.react-tagsinput) {
    min-height: auto;
    padding: 5px;
    border: none;
    :global(span) {
      margin: 0px;
    }
  }
  :global(.react-tagsinput-tag) {
    margin: 0;
  }
}
.filterMenu {
  padding: 0px;
  position: relative;
  .filterTypeList {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    width: max-content;
    li {
      padding: 0px 12px;
      cursor: pointer;
      &:hover {
        background-color: $white2;
      }
      height: 34px;
      line-height: 34px;
      @include base-font;
      color: $dark;
    }
  }
  .filterOptionsOfType {
    position: absolute;
    top: 0;
    width: 0;
    right: -3px;
    .filterOptions {
      > div:first-child {
        overflow: auto;
        max-height: 200px;
      }
    }
  }
}