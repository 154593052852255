@import 'fonts.scss';
@import 'colors.scss';
@import 'components.scss';

.accountDetailContainer {
  background-color: $white2;
  min-height: 100%;
  height: 1px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .title {
    background-color: $white;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px;
  }
  .titleWithBreadcrumbs {
    background-color: $white;
    height: 118px;
    flex-shrink: 0;
    position: relative;
    .title {
      left: 30px;
      top: 30px;
      padding: 0;
      position: absolute;
    }
    .breadcrumbs {
      @include base-font;
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      color: $dark;
      left: 30px;
      bottom: 20px;
      position: absolute;
      .slash {
        margin-left: 5px;
        margin-right: 5px;
        color: $dark;
      }
    }
  }
  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    .buttonArea {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 30px;
      flex-shrink: 0;
      >input {
        display: none;
      }
      > div, > a {
          cursor: pointer;
          margin-left: 30px;
          @include base-font;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          color: $theme1;
          &:hover {
            color: $theme2;
          }
      }
    }
  }
  .basic {
    display: flex;
    flex-wrap: wrap;
  }
  .accountName {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
    padding: 20px 0px 11px 0px;
  }

  .sepLine {
    height: 1px;
    border-top: 1px solid $super-light;
  }

  .accountDetail {
    padding: 0px 30px 10px 30px;
    margin-bottom: 28px;
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
  }

  .bottomArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .tabContainer {
      background-color: $white;
      padding-top: 30px;
      @include tabs;
    }
  }
}