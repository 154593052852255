@import 'fonts.scss';

.cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  .selectCheckBox {
    min-width: 50px;
    height: auto;
    padding-left: 3px;
    input {
      display: none;
    }
    input + label {
      margin-right: 0;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
  }
}
