@import '../../colors.scss';
@import '../../components.scss';

.agency-admin {
  color: #1a1100;
  background-color: #e69500;
}

.agency-manager {
  color:#4d3200;
  background-color: #ffb833;
}

.agency-sales {
  color: #805300;
  background-color: #ffe4b3;
}

.agency-account {
  color: #805300;
  background-color: #ffd280;
}

.admin {
  color: #4c0000;
  background-color: #f55;
}

.advertiser-admin {
  color: #4c4d00;
  background-color: #d2e600;
}

.advertiser-sales {
  color: #660;
  background-color: #ef3;
}

.advertiser-report {
  color: #660;
  background-color: #f9ffb3;
}

.role-container {
  @include badge;
  color: $white;
  background-color: $info;
  display: inline-block;
  margin: auto 10px auto 0px;
  white-space: nowrap;
}

.company-name {
  @include button-font;
  @include medium;
  margin: auto 0px;
  text-align: left;
}
