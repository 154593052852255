@import 'colors.scss';
@import 'fonts.scss';

.agencyMemberList {
  height: 100%;
  th,
  td {
    &:first-child {
      padding-left: 64px;
    }
    vertical-align: middle;
  }
  tr {
    .floatingEditArea {
      display: none;
    }

    &:hover {
      .floatingEditArea {
        display: flex;
      }
    }
  }

  .email {
    min-width: 300px;
    width: 30%;
    padding-right: 24px;
  }
  .name,
  .status {
    min-width: 200px;
    width: 20%;
    padding-right: 24px;
  }
  .name {
    padding-left: 64px;
  }
  .permission {
    min-width: 250px;
    padding-right: 24px;
  }
  .nameCell {
    .name {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
      padding: 0;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
}
