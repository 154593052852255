@import 'fonts.scss';

.nativeBannerPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  .previewArea {
    width: 757px;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .previewComponent {
      position: absolute;
      margin: auto;
      opacity: 0;
      pointer-events: none;
      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .previewImage {
      position: absolute;
      margin: auto;
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .buttonArea {
    span {
      margin-left: 15px;
      margin-right: 15px;
      @include base-font;
      color: $dark;
      cursor: pointer;
      &.active {
        color: $theme1;
      }
    }
  }
}