@import '../../fonts.scss';
@import '../../colors.scss';

.modifyCreativeActivationStateContent {

  .sliderArea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .title {
    margin-top: 30px;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    @include base-font;
    line-height: 1.2;
    color: $black;
    margin-left: 30px;
  }

  .campaignOption {
    padding-left: 30px;
    padding-right: 30px;
  }
}