@import 'fonts.scss';
@import 'colors.scss';

.iconWithTooltip {
  cursor: pointer;
  color: $theme2;
  &.disabled {
    cursor: not-allowed !important;
    color: $theme6;
  }
}

:global {
  .tooltip {
    &[id*="-iconTooltip"] {
      pointer-events: none;
      .tooltip-inner {
        font-size: 12px;
        font-weight: 500;
        @include base-font;
        max-width: inherit;
      }
    }
  }
}