.tableHeaderHint {
  display: flex;
  align-items: center;
  svg {
    // margin-top: 2px;
    margin-right: 7px;
    margin-left: 7px;
    color: #a8a8aa;
    cursor: default;
  }
}
