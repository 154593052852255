@import 'fonts.scss';
@import 'colors.scss';
.errorMessage {
    color: $danger;
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
}

.container {
    padding: 30px 0 40px 0;
    overflow: hidden;
}