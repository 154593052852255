@import 'colors.scss';

.outdoorCreativeDetailContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .outdoorCreativeDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .videoImagePreview {
      width: 270px;
      height: 480px;
    }
    .h5Preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $super-light;
      width: 200px;
      height: 140px;
      svg {
        color: $light;
        font-size: 54px;
      }
    }
  }
}
