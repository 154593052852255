@import 'colors.scss';
@import 'fonts.scss';

.customInputContainer {
  width: 100%;
  padding: 0 12px 0 12px;
  background-color: $white3;

  .descriptionArea {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 12px 0;
    .leftDescription {
      @include base-font;
      height: 14px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
    }

    .rightDescription {
      @include base-font;
      height: 12px;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      text-align: right;
      color: $dark;
    }
  }

  textarea {
    width: 100%;
    height: 170px;
    vertical-align: top;
  }

  .buttonArea {
    display: flex;

    // justify-content: space-between;
    padding: 12px 0 16px 0;

    .cancelButton {
      @include base-font;
      height: 14px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      color: $black;
      cursor: pointer;
      margin-right: 20px;
    }

    .addButton {
      @include base-font;
      height: 14px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      color: #5845a5;
      cursor: pointer;
      margin-right: 20px;
    }
  }
}