@import 'fonts.scss';

.categoryTitle {
  @include base-font;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.18;
  color: $dark;
  margin: 10px 0 10px 0;
  display: flex;
  align-items: center;

  .backBtn  {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $primary1;
    cursor: pointer;
    margin-left: 30px;
  }
}