@import 'colors.scss';

.breadcrumbs {
  .slash {
    margin-left: 5px;
    margin-right: 5px;
    color: $dark;
  }
  span:last-child {
    a {
      pointer-events: none;
      color: $dark;
    }
  }
}