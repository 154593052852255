@import 'fonts.scss';

.tableCell {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  .hintDetailBox {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    max-width: 400px;
    width: max-content;
    height: auto;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    left: 75px;
    top: 0px;
    z-index: 3;
    .toggleDetail {
      width: auto;
      padding: 6px 0px;
    
      .closeBtn {
        display: inline;
        color: $dark;
        font-size: 16px;
        padding: 0px;
        margin-left: 10px;
      }
    }
    .hintDetail {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 0px 20px 10px 16px;
    }
  }
}
