@import 'colors';

.previewPlaceholder {
  min-width: 100px;
  min-height: 100px;
  border: 1px solid $super-light;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  .loadingCircle {
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid $dark;
    animation-name: roller;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    border-left-color: transparent;
  }
  
  @keyframes roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}