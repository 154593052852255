@import 'colors.scss';

.dayPart {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  &.error {
    border: 1px solid $danger;
  }
}

.daypartOptions {
  max-width: 212px;
  :global(.react-select__placeholder) {
    color: $black;
  }
  &.error {
    :global(.react-select__control) {
      border: 1px solid $danger;
    }
  }
}

.dayOfWeekColumn {
  margin-top: 30px;
}

.hint {
  height: 30px;
  display: flex;
  align-items: center;
  label {
    width: 324px;
    margin: 0;
    text-align: left;
    padding-left: 5px;
  }
}

.dayOfWeek {
  text-align: center;
  min-width: 100px;
  height: 27px;
  padding: 0px 10px;
  border: 1px solid #dddddd;
  border-bottom: none;
  border-right: none;
  background-color: #E2F0FF;
  user-select: none;
  cursor: pointer;
  &:first-child {
    border: none;
    background-color: transparent;
    pointer-events: none;
  }
  &:last-child {
    border-bottom: 1px solid #dddddd;
  }
}

.row {
  display: flex;
  flex-direction: row;
  &:last-child {
    .cell {
      border-bottom: 1px solid #dddddd;
    }
  }
}

.cell {
  border: 1px solid #dddddd;
  width: 27px;
  height: 27px;
  text-align: center;
  background-color: #eee;
  border-right: none;
  border-bottom: none;
  &:last-child {
    border-right: 1px solid #dddddd;
  }
  &.header {
    background-color: #E2F0FF;
    user-select: none;
    cursor: pointer;
  }

  &.selected {
    background-color: #428bca;
    &::after {
      content: "\2713";
    }
  }
}