@import 'fonts.scss';
@import 'colors.scss';
@import 'components.scss';

.segmentListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  td {
    vertical-align: middle;
  }
  .segmentList {
    flex-grow: 1;
    background-color: $white2;
  }
  .nameIdColumn {
    min-width: 350px;
    width: 37%;
    padding-right: 24px;
    word-break: break-all;
  }
  .durationColumn, .segmentCountColumn, .subSegmentAmountColumn {
    width: 21%;
    min-width: 200px;
    padding-right: 24px;
  }
}