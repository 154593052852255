@import 'fonts';

.countEstimateArea {
  .countHint {
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $dark;
  }
}