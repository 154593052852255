@import 'colors';

.contentArea {
  th:first-child, td:first-child {
    padding-left: 60px;
  }
  th:last-child, td:last-child {
    padding-right: 60px;
  }
  .id {
    width: 300px;
    max-width: 400px;
    overflow-wrap: break-word;
  }
  .status, .spent {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
  .budget {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
  .errorTip {
    min-height: 14px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $danger;
    width: 100%;
    display: block;
    margin-top: 2px;
  }
  .error {
    border-radius: 2px;
    border: 1px solid $danger !important;
  }
  :global(.form-control) {
    max-width: 300px;
  }
  .dangerHint {
    color: $danger;
  }
}