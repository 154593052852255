@import 'fonts.scss';

.segmentFormContainer {
  position: relative;
  background-color: $white3;
  min-height: 100%;
  :global(.form-group.row) {
    margin-right: 0;
    margin-left: 0;
  }
  .formContent {
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .formButtons {
    margin-left: 30px;
    button,
    a {
      margin-left: 20px;
    }

    padding-bottom: 60px;
  }
  .segmentLimitation {
    display: flex;
    .inventory {
      width: max-content;
      display: none;
      &.active {
        display: block;
      }
      &.error {
        > div {
          border-color: $danger;
        }
      }
      > div {
        overflow-x: hidden;
      }
    }
    .selectedLimitation {
      display: flex;
      flex-direction: column;
    }
  }
  .editTitle {
    line-height: 36px;
    width: max-content;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $theme1;
    cursor: pointer;
    &:hover {
        color: $theme2;
    }
  }
  .selectedLimitationArea {
    background: $white;
    width: 100%;
    padding: 0px 10% 40px 0px;
    :global(.form-group.row) {
      >:global(.form-label) {
          flex-basis: 100%;
          text-align: left;
          margin-bottom: 6px;
      }
    }
    &.short {
      padding-left: 40px;
    }
  }
  .finalLogicValue {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
    span {
      font-weight: bold;
    }
  }
  .logic {
    :global(textarea.form-control) {
     height: 150px;
    }
  }
  :global(.errorTip) {
    margin-top: 5px;
    min-height: 14px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    width: 100%;
    display: block;
    color: $danger;
  }
}