.keywordContainer {
  height: 100%;
  padding: 13px;
  position: relative;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-left: 20px;
}

.descript {
  font-weight: bold;
}

.body {
  margin: 10px;
}

.and {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.input {
  margin: 10px 0;
}

.language {
  display: flex;

  >:global(.form-check) {
    padding-left: 0;
  }
}

.search {
  margin: 10px 0;
}

.hourUpdate {
  color: red;
}

.buttonGroup {
  position: absolute;
  bottom: 60px;
}