@import 'fonts.scss';
@import 'components.scss';

.productSetList {
  $topAreaHeight: 77px;
  height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: $white2;
    > div {
      height: 100%;
    }
    
    @include tabs;
    :global(.nav) {
      padding-top: 30px;
      height: 72px;
    }
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  thead th,
  td {
    &.editBtns {
      width: 0;
      min-width: 0;
      max-width: 0;
      padding: 0px;
    }
  }
  th:first-child,
  td:first-child {
    padding-left: 64px;
  }
  .floatingEditArea {
    display: none;
  }
  .nameCell {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    min-width: 250px;
    .name {
      @include base-font;
      width: 250px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }
    .idInfo {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
  .nameCellWithIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 24px;
    }
  }
  tr:hover {
    .detailBtn {
      visibility: visible;
    }
    .floatingEditArea {
      display: flex;
    }
    td {
      background-color: $white2;
    }
  }
  .filterArea {
    a {
      margin-left: 30px;
    }
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    height: $topAreaHeight;
    background-color: $white;
    position: relative;
    :global(.search-bar) {
      width: 370px;
      margin: 0px;
      position: absolute;
      right: 30px;
    }
    .createBtn {
      color: $white;
    }
  }
  .id {
    min-width: 350px;
    max-width: 350px;
    width: 350px;
    padding-right: 48px;
    word-break: break-all;
    user-select: text;
  }
  .update_frequency,
  .update_time,
  .product_count {
    white-space: nowrap;
  }
  .detailBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    visibility: hidden;
    margin-left: 10px;
    color: $dark;
    svg {
      color: $dark;
    }
  }
}

.detailModal {
  ul {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding-left: 20px;
    li {
      @include base-font;
      padding: 3px 0px;
      color: $dark;
    }
  }
}
