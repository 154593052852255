@import 'colors.scss';

.switchComponent {
  margin-top: 7px;

  &.error {
    border-radius: 2px;
    border: 1px solid $danger !important;
  
    &:global(.react-toggle) {
      border: none !important;
      :global(.react-toggle-track) {
        border: 1px solid $danger;
      }
    }
  }
}