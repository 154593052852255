@import 'colors.scss';
@import 'fonts.scss';

.component {
  background: $white;
  box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
  padding: 40px 30px;
  :global(.col-sm-9) {
    .adLogoType {
      max-width: 424px;
      min-width: 424px;
    }
    .previewArea {
      position: relative;
      width: fit-content;
      .logoPreview {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
      .defaultLogo {
        position: absolute;
        top: -11px;
        right: -8px;
        transform: scale(0.7);
        cursor: pointer;
      }
      .tip {
        display: block;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #98969d;
        margin-top: 2px;
        width: 100%;
      }
    }
  }
}

.logoInput {
  > div {
    width: 150px;
    height: 150px;
    box-sizing: content-box;
    border: dashed 1px $super-light;
    background-color: $white;
  }
  
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.showSolidBorder {
    > div {
      border: 1px solid $super-light;
    }
  }

  &.hideBorder {
    > div {
      border: none;
    }
  }
}