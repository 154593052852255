@import '../../../colors.scss';
@import '../../../fonts.scss';

.groupOptionSelection {
  user-select: none;
  background-color: $white;
  z-index: 4;
  position: relative;

  .selectedOption {
    display: flex;
    align-items: center;
    width: 424px;
    height: 34px;
    border-radius: 2px;
    border: 1px solid $super-light;
    background-color: $white;
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: $black;
    padding-left: 12px;
    position: relative;
    cursor: pointer;
    svg {
      position: absolute;
      right: 15px;
      top: 10px;
    }
    &.isPlaceholder {
      color: $super-light;
    }
    &.customStyle {
      height: 38px;
    }
  }

  .dropdownArea {
    position: absolute;
    &.open {
      display: block;
    }
    display: none;
    margin-top: 3px;
    border-radius: 2px;
    width: 396px;
    border: 1px solid $super-light;
    background-color: $white;
    :global(.search-bar) {
      margin: 0;
      border: none;
      border-bottom: 1px solid $super-light;
    }
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    color: $black;

    .selectAll {
      padding: 6px;
      padding-left: 30px;
      border-bottom: 1px solid $super-light;
      cursor: pointer;
      &:hover {
        background-color: $theme5;
      }
    }
    .options {
      max-height: 317px;
      overflow: auto;
    }
    .groups {
      .group {
        &:not(:first-child) {
          border-top: 1px solid $super-light;
        }
        .groupTitle {
          @include base-font;
          padding-left: 12px;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.33;
          padding-top: 4px;
          padding-bottom: 2px;
          color: $dark;
        }
        .option {
          word-break: break-all;
          min-height: 34px;
          padding: 5px 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: $theme5;
          }
        }
      }
    }
  }
}