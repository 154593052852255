@import 'colors.scss';
@import 'fonts.scss';

.nativeCreativeImagePreview {
  object-fit: contain;
  width: 218px;
  height: 115px;
  border: 1px solid $super-light;

  img {
    min-width: 216px;
    max-width: 216px;
    width: 216px;
    min-height: 113px;
    max-height: 113px;
    height: 113px;
  }

  &.icon {
    width: 124px;
    height: 124px;

    img {
      min-width: 124px;
      max-width: 124px;
      width: 124px;
      min-height: 124px;
      max-height: 124px;
      height: 124px;
    }
  }
}

.videoCreativePreview {
  width: 140px;
  height: 95px;
}

.fbVideoPreview {
  width: 100%;
  height: 100%;
  img {
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
  }
}

.thirdPartyVideoCreativePreview {
  width: 200px;
  height: 140px;
  margin: auto;
}

.normalCreativePreview,
.ppsCreativePreview,
.retailProductCreativePreview,
.customCreativePreview,
.htmlCreativePreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
  }
}

.oneForAllDisplayCreativePreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
  }
}

.ppsCreativePreview,
.retailProductCreativePreview,
.customCreativePreview,
.htmlCreativePreview {
  &.border {
    border: 1px solid $super-light;
    width: 200px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.retailProductCreativePlaceholder,
.videoPreviewPlaceholder,
.outdoorPreviewPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 140px;
  border: 1px solid $super-light;
  img {
    margin-bottom: 10px;
  }
  span {
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    text-align: center;
    color: $dark;
  }
}

.outdoorPreviewPlaceholder {
  svg {
    color: $light;
    font-size: 54px;
  }
}

.outdoorImgPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
