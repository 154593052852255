@import 'fonts.scss';

.campaignDashboard {
  min-width: 1024;
  background-color: $white2;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: min-content;

  th:first-child,
  td:first-child {
    padding-left: 64px;
  }

  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    height: 84px;
    padding: 30px;
    background-color: #fff;
  }
  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }
  .summary {
    padding-top: 20px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: #fff;
  }
  .campaignList {
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .filterArea {
      width: 100%;
      flex-shrink: 0;
      border-bottom: 1px solid #dee2e6;
      display: flex;
      min-height: 92px;
      background-color: $white;
      :global {
        .react-select__control {
          min-height: 34px !important;
          height: 34px !important;
        }
      }
      .flexArea {
        display: flex;
        flex-wrap: wrap;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 20px;
      }
      .dayFilter {
        display: flex;
        > div:first-child, > div:nth-child(3) {
          border-top: 1px solid $super-light;
          border-bottom: 1px solid $super-light;
          height: 34px;
          padding-top: 6px;
          padding-left: 10px;
          padding-right: 10px;
          @include base-font;
          font-size: 14px;
        }
        > div:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-left: 1px solid $super-light;
        }
      }
      .daySelect {
        width: 80px;
        z-index: 3;
        > div {
          border-radius: 0;
        }
      }
      .timeOption {
        width: 100px;
        z-index: 3;
        > div {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
      .dayFilter, .filterWithTitle {
        z-index: 3;
        margin-right: 10px;
      }
      .dayFilter {
        margin-top: 26px;
      }
      .filter {
        z-index: 3;
        width: 200px;
      }
      :global(.search-bar) {
        width: 353px;
        margin: 26px 10px auto 0px;
        border-radius: 3px;
      }
    }
    .filterWithTitle {
      margin-top: 3px;
      .filterTitle {
        @include base-font;
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: 5px;
        color: #3f4848;
      }
    }
    .searchButton {
      margin: 43px 20px auto auto;
      height: 34px;
      padding-top: 9px;
      width: 120px;
      word-break: keep-all;
    }
    .listContent {
      flex-grow: 1;
      height: 1px;
    }
    .downloadContainer {
      position: relative;
      height: 70px;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px solid $super-light;
  
      > .downloadWording {
        margin-right: 30px;
        color: $dark;
        font-size: 14px;
        cursor: pointer;
  
        > .downloadIcon {
          margin-right: 5px;
        }
      }
    }

    .info {
      user-select: text;
      max-width: 220px;
      min-width: 220px;
      .campaignName {
        @include base-font;
        width: 220px;
        height: 44px;
        font-size: 14px;
        line-height: 1.57;
        overflow-wrap: anywhere;
        &.link {
          color: $theme1;
          word-break: break-word;
        }
      }
      .campaignID {
        display: flex;
        flex-direction: column;
        @include base-font;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.2;
        color: $dark;
        padding: 4px 0px;
        div {
          margin: 1px 0px;
        }
      }
    }

    .orderName {
      overflow-wrap: anywhere;
    }

    .progressContent {
      white-space: nowrap;
      .progress {
        width: 60px;
        margin-bottom: 4px;
      }
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
    }

    .name {
      min-width: 300px;
      max-width: 300px;
      width: 300px;
      padding-right: 15px;
      word-break: break-all;
      user-select: text;
    }

    .agency,
    .advertiser,
    .bidPrice,
    .ctr,
    .magnificationRatio,
    .startDate,
    .endDate {
      white-space: nowrap;
    }

    th.order {
      white-space: nowrap;
    }

    .order {
      max-width: 220px;
    }

    .progress {
      min-width: 100px;
      padding-left: 10px;
      padding-right: 21px;
      white-space: nowrap;
    }

    .campaignFloatingEditArea {
      display: none;
    }

    tr:hover {
      .campaignFloatingEditArea {
        display: flex;
      }
    }
  }
}