@import 'colors.scss';
@import 'fonts.scss';

.loginForm {
  .form-image {
    height: 510px;
    margin: auto;
    &.fill {
      width: 574px;
      height: 640px;
    }
  }

  .form-title {
    height: 40px;
    margin-top: 140px;
    margin-bottom: 6px;
    margin-left: -10px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: auto;
    width: 450px;
    background-color: $white3;
    .iconGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
    .subtitle {
      font-size: 20px;
      font-weight: 500;
    }
    .input-group {
      display: flex;
      flex-direction: column;
      &.with-error {
        input {
          border: 1px solid $danger;
        }
      }
      width: 320px;
      margin-bottom: 20px;
      position: relative;
      .password-input {
        padding-right: 30px;
      }
      label {
        width: fit-content;
        height: 14px;
        @include base-font;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.17;
        color: $login-page-font-color;
        margin: 0;
        margin-bottom: 8px;
      }
      input {
        line-height: 24px;
        width: 320px;
        height: 34px;
        box-sizing: border-box;
      }
      svg {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 32px;
        width: 14px;
      }
      .input-error {
        @include base-font;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.17;
        color: $danger;
        width: 100%;
        position: absolute;
        bottom: -15px;
      }
    }
    button {
      margin-top: 40px;
      padding: 9px 30px;
      width: fit-content;
    }
    .underscore-text {
      margin-top: 20px;
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      text-align: center;
      color: $login-page-font-color;
      text-decoration: underline;
    }
  }
  .fbLoginButton {
    height: 34px;
    margin-top: 30px;
    background-color: rgb(24, 119, 242);
    border-color: rgb(24, 119, 242);
    color: $white;
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    svg {
      margin-right: 8px;
      font-size: 20px;
    }
    span {
      margin-bottom: 3px;
    }
  }
}

@media (max-width: 1024px) {
  .loginForm {
    form {
      .input-group {
        width: 500px;
        input {
          width: 500px;
        }
      }
    }
  }
}

@media (max-width:640px) {
  .loginForm {
    form {
      .input-group {
        width: 311px;
        input {
          width: 311px;
        }
      }
    }
  }
}
