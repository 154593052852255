@import 'colors.scss';
@import 'fonts.scss';
@import 'components';

.productSegmentFormContainer {
  @include tab-form-content;
  .fieldContent {
    overflow: visible;
  }
  background-color: $white3;
  padding-bottom: 56px;
  height: 100%;

  :global(form) {
    background-color: $white3;
  }

  :global(.form-control) {
    height: inherit;
  }

  .titleArea {
    background-color: $white;
    height: 120px;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
  }

  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }

  :global(.col-sm-3) {
    label {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
    }
  }

  :global(.col-sm-9) {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $black;
  }

  :global(.col-sm-9) {
    input {
      max-width: 424px;
    }
    :global(.input-group) {
      max-width: 424px;
    }
    
    textarea {
      height: 100px;
      max-width: 424px;
    }
  }

  .eventRuleContainer {
    display: flex;
    flex-direction: column;
    background-color: $white3;
    overflow: visible;
    width: fit-content;
    min-width: 100%;

    .ruleCloseBtn {
      margin: 5px 0px 0px auto;
      height: 40px;
      button {
        color: $light;
        &:hover {
          color: $super-light;
        }
      }
    }

    .eventRule {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: visible;
      padding: 0px 110px 40px 30px;

      :global(.form-check ) {
        padding-left: 0;
      }
      
      .fieldCloseBtn {
        margin-left: 10px;
        button {
          color: $light;
          &:hover {
            color: $super-light;
          }
        }
      }
    }

    .selectedFilterFieldFormGroup {

      :global(.form-group) {
        align-items: center;
        margin-bottom: inherit;
      }

      .filterFieldRow {
        display: flex;
        width: max-content;
        :global {
          input.form-control {
            height: 38px;
          }
        }
      }
    }

    .filterFieldFormGroup {
      margin-bottom: inherit;

      .filterFieldRow {
        display: flex;
        :global {
          input.form-control {
            height: 38px;
          }
        }
      }
    }

    .aggregationFieldFormGroup {
      margin-bottom: inherit;

      :global(.form-group) {
        margin-bottom: inherit;
      }
      
      .filterFieldRow {
        display: flex;
        :global {
          input.form-control {
            height: 38px;
          }
        }
      }
    }
  }

  .combinationText {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
  }

  .buttonArea {
    button {
      margin-left: 20px;
    }
    button:first-child {
      margin-left: 30px;
    }
    padding-top: 30px;
    padding-bottom: 60px;
    background-color: $white3;
  }
}