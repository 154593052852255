@import 'fonts.scss';

.infoDisplay {
  padding: 0px 30px 10px 30px;
  background-color: $white;
  
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    .editArea {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 30px;
      flex-shrink: 0;
    }
  }
  .information {
    display: flex;
    flex-wrap: wrap;
  }
  .title {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
    padding: 20px 0px 11px 0px;
  }
  .section {
    min-width: 100%;
    padding: 0;
  }
}