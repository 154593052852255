@import 'fonts.scss';

.thirdPartyVideoPreview {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid $super-light;
  &.canClick {
    cursor: pointer;
  }

  img {
    margin-bottom: 10px;
    object-fit: contain;
  }
  span {
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    text-align: center;
    color: $dark;
  }
}