@import 'fonts.scss';

.filterArea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.agencyList, .advertiserList {
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-right: 30px;
  .title {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $dark;
    margin-right: 10px;
    margin-top: 5px;
  }
}
.agencyList {
  z-index: 5;
}