@import 'fonts.scss';
@import 'colors.scss';
@import 'components.scss';
@import '~font-awesome/css/font-awesome.min.css';

.campaignList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-width: 900px;
  .campaignCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .info {
      user-select: text;
      max-width: 220px;
      min-width: 220px;
      .campaignName {
        @include base-font;
        width: 220px;
        height: 44px;
        font-size: 14px;
        line-height: 1.57;
        &.link {
          color: $theme1;
          word-break: break-word;
        }
      }
      .campaignID {
        display: flex;
        flex-direction: column;
        @include base-font;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.2;
        color: $dark;
        padding: 4px 0px;
        div {
          margin: 1px 0px;
        }
        .draftIcon {
          width: max-content;
          padding: 2px 10px;
          font-size: 12px;
          line-height: 1.17;
          color: $theme1;
          border-radius: 12px;
          border: solid 1px $theme1;
          margin: 5px 0px;
        }
      }
    }
    .campaignEditBtn,
    .detailBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      visibility: hidden;
      margin-left: 10px;
      color: $dark;
    }
    .stateContent {
      @include base-font;
      transform: scale(0.95);
      font-size: 12px;
      font-weight: 500;
      color: $white;
      height: 22px;
      width: max-content;
      border-radius: 11px;
      background-color: $theme1;
      line-height: 22px;
      padding: 0px 10px;
      margin: auto;
      box-sizing: content-box;
      &.budgetRemain,
      &.deleted {
        background-color: $danger;
      }
      &.deactive {
        background-color: $black;
      }
      &.end {
        background-color: $light;
      }
      &.noCreatives {
        background-color: $black;
      }
      &.stopping {
        color: $theme4;
        border: 1px solid $theme4;
        background-color: $white;
      }
      &.notStart {
        color: $dark;
        border: 1px solid $dark;
        background-color: $white;
      }
    }
    .progressContent {
      white-space: nowrap;
      .progress {
        width: 60px;
        margin-bottom: 4px;
      }
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
    }
    .priceContent {
      display: grid;
      grid-template-areas: 'priceModelTitle priceModelValue' 'optimizeTitle optimizeValue' 'priceCapTitle priceCapValue';
      .priceModelTitle {
        grid-area: priceModelTitle;
        padding-right: 5px;
      }
      .priceModelValue {
        grid-area: priceModelValue;
      }
      .optimizeTitle {
        grid-area: optimizeTitle;
        padding-right: 5px;
      }
      .optimizeValue {
        grid-area: optimizeValue;
      }
      .priceCapTitle {
        grid-area: priceCapTitle;
        padding-right: 5px;
      }
      .priceCapValue {
        grid-area: priceCapValue;
      }
    }
    .priceGroupContent {
      display: grid;
      grid-template-areas: 'priceModelTitle priceModelValue' 'optimizeTitle optimizeValue' 'fbPriceCapTitle fbPriceCapValue' 'rtbPriceCapTitle rtbPriceCapValue';
      .priceModelTitle {
        grid-area: priceModelTitle;
        padding-right: 5px;
      }
      .priceModelValue {
        grid-area: priceModelValue;
      }
      .optimizeTitle {
        grid-area: optimizeTitle;
        padding-right: 5px;
      }
      .optimizeValue {
        grid-area: optimizeValue;
      }
      .fbPriceCapTitle {
        grid-area: fbPriceCapTitle;
        padding-right: 5px;
      }
      .fbPriceCapValue {
        grid-area: fbPriceCapValue;
      }
      .rtbPriceCapTitle {
        grid-area: rtbPriceCapTitle;
        padding-right: 5px;
      }
      .rtbPriceCapValue {
        grid-area: rtbPriceCapValue;
      }
    }
    .scheduleContent,
    .trackingContent {
      width: max-content;
    }
    .scheduleContent,
    .budgetContent,
    .priceContent,
    .trackingContent,
    .performanceDataContent {
      @include base-font;
      font-size: 14px;
      line-height: 1.57;
      color: $dark;
    }
    .budgetContent,
    .priceContent,
    .priceGroupContent {
      min-width: 200px;
    }
    .performanceDataContent {
      text-align: right;
      width: 100%;
    }
    .budgetContent {
      max-width: calc(100% - 28px);
      display: flex;
      > div:nth-child(1) {
        padding-right: 5px;
      }
    }
    .tagsContent {
      display: flex;
      flex-wrap: wrap;
      max-width: 172px;
      min-width: 150px;
      div {
        width: max-content;
        min-height: 22px;
        border-radius: 11px;
        background-color: $light;
        @include base-font;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.17;
        color: $white;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        margin: 0 5px 5px 0;
      }
    }
  }
  .nameHeader {
    position: relative;
    padding-left: 50px;
    input {
      display: none;
    }
    input + label {
      position: absolute;
      left: 3px;
      top: -5px;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
    input:indeterminate + label::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 13px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
        4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
        8px 0 0 $white;
    }
  }
  .selectCheckBox {
    min-width: 48px;
    height: auto;
    padding-left: 3px;
    input {
      display: none;
    }
    input + label {
      margin-right: 0;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
  }
  tr:hover {
    .campaignFloatingEditArea {
      display: flex;
    }
    .campaignEditBtn,
    .detailBtn {
      visibility: visible;
    }
    &.draftBg,
    &.lightDanger {
      background-color: $white2;
      .nameColumn {
        background-color: $white2 !important;
      }
    }
  }
  tr.lightDanger {
    background-color: $light-danger;
    .nameColumn {
      background-color: $light-danger !important;
    }
  }
  tr.draftBg {
    background-color: $theme3;
    .nameColumn {
      background-color: $theme3 !important;
    }
  }
  .campaignFloatingEditArea {
    display: none;
  }
  .campaignListTitleBar {
    border: 1px solid $super-light;
    background-color: $white;
    border-left: none !important;
    border-right: none !important;
    min-height: 77px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .createCampaignBtn {
      width: 134px;
      height: 34px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      text-align: center;
      @include base-font;
      color: $white;
      padding: 10px 0;
      border: none;
      margin-left: 30px;
    }
    .campaignListTitleFilterArea {
      margin-right: 30px;
      :global(.search-bar) {
        width: 370px;
        margin: 0px;
        margin-left: 15px;
      }
    }
    .deleteDraftTab {
      margin-left: auto;
      svg {
        margin-right: 6px;
      }
    }
  }
  .contentArea {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 77px);
    .metricsOptionsContainer {
      background-color: $white;
      height: 72px;
      border-bottom: solid 1px #979797;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 30px;
      .metricsOptions {
        width: 200px;
        z-index: 10;
      }
    }
    .listContainer {
      flex-grow: 1;
      max-height: calc(100% - 70px);
    }
  }
  // :global(input[type='checkbox']) {
  //   position: relative;
  //   opacity: 1;
  // }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  :global(.sortable) {
    cursor: pointer;
  }
  th {
    outline: none;
    height: 46px;
    white-space: nowrap;
  }
  :global(.react-bootstrap-table-sort-order),
  :global(.order) {
    margin-left: 5px;
  }
  .editBtnsColumn {
    width: 0;
    padding: 0px;
  }
  .nameColumn {
    min-width: 300px;
    max-width: 300px;
    width: 300px;
    padding-right: 15px;
    word-break: break-all;
    user-select: text;
  }
  .creativeColumn {
    padding-left: 10px;
    padding-right: 15px;
  }
  .scheduleColumn {
    padding-left: 10px;
    padding-right: 15px;
  }
  .progressColumn {
    min-width: 100px;
    padding-left: 10px;
    padding-right: 21px;
    white-space: nowrap;
  }
  .retailType {
    padding-left: 10px;
    padding-right: 21px;
    white-space: nowrap;
  }
  .budgetColumn {
    min-width: 180px;
    padding-left: 10px;
    padding-right: 15px;
    white-space: nowrap;
  }
  .priceColumn {
    min-width: 200px;
    padding-left: 10px;
    padding-right: 15px;
    white-space: nowrap;
  }
  .spents {
    white-space: nowrap;
  }
  .optimize {
    white-space: nowrap;
    .optimizationCell {
      display: grid;
      grid-template-columns: auto 10px 1fr;
      .title {
        grid-column: 1/2;
        padding-right: 5px;
      }
    }
  }
  .limitationColumn {
    min-width: 320px;
    padding-left: 10px;
    padding-right: 15px;
    word-break: break-word;
  }
  .trackingColumn {
    padding-left: 10px;
    padding-right: 15px;
    word-break: break-word;
  }
  .tagsColumn {
    padding-left: 10px;
    padding-right: 15px;
    word-break: break-all;
  }
  .clicksColumn,
  .convertColumn,
  .viewColumn,
  .impresColumn,
  .viewableColumn,
  .ctrColumn,
  .vctrColumn,
  .cvrColumn,
  .cpcColumn,
  .cpaColumn,
  .resultsColumn,
  .spents,
  .uuCountColumn {
    padding-left: 10px;
    padding-right: 15px;
    text-align: right;
  }
  .viewRateColumn,
  .viewableViewRateColumn {
    padding-left: 10px;
    padding-right: 15px;
    text-align: right;
  }
  .summaryRow {
    pointer-events: none;
    height: 32px;
    background-color: $super-light;
    td {
      @include base-font;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      color: $dark;
    }
    .nameColumn {
      background-color: $super-light !important;
      padding-left: 64px;
    }
  }
  .resultCell {
    position: relative;
    .value {
      color: $dark;
    }
    .objective {
      font-size: 10px;
      color: $light;
      position: absolute;
      right: 0;
    }
  }
  .campaignListTitleBar {
    height: 77px;
    margin-top: 0;
    .tipContainer {
      position: relative;
    }
    .tipContainer.block {
      &:hover {
        cursor: not-allowed;
        .tooltip {
          display: block;
        }
      }
    }
  }
  .campaignListTitleBar .tooltip {
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: $black;
    border-radius: 0.25rem;
    top: unset;
    bottom: -25px;
    height: auto;
  }
  .createCampaign {
    display: inline-block;
  }
  .tooltip {
    display: none;
    position: absolute;
    left: 65px;
    top: 20px;
    z-index: 2;
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    @include base-font;
    line-height: 1.17;
    color: $dark;
    background-color: $white;
    padding: 0 5px;
    white-space: nowrap;
  }
  @include tabs;
}
