@import 'fonts.scss';

.draftSetupStateContent {

  .title {
    @include base_font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 20px 30px;
  }

  :global(.row) {
    margin-left: 0;
    margin-right: 0;
  }

  .filterAdder {
    color: $theme1;
    display: flex;
    align-items: center;
    cursor: pointer;
    .filterToAddList {
      @include base_font;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
      margin-left: 6px;
    }
  }

  .sepLine {
    border-top: 1px solid $super-light;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
  }
}