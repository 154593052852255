@import 'fonts.scss';

.rectImageInput {
  width: 382px;
  height: 200px;
}

.squareImageInput {
  width: 200px;
  height: 200px;
}

.nativeBannerPreviewArea {
  display: flex;
  align-items: center;
  justify-content: center;
}