@import 'fonts.scss';

.sepLine {
  height: 1px;
  border-top: solid 1px $super-light;
}

.videoPreview {
  max-width: min-content;
  .thirdPartyVideoPreviewContainer {
    width: 300px;
    height: 150px;
  }
  .videoInfo {
    @include base-font;
    display: -webkit-box;
    min-height: 22px;
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    word-break: break-all;
    max-height: 32px;
    -webkit-line-clamp: 2;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    div {
      color: $black;
    }
  }
}