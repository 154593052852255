.previewStep {
  height: 100%;
  overflow: auto;
  background-color: #f4f4f4;
  .mainArea {
    background-color: #ffffff;
    box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
  }
  .buttonArea {
    margin: 30px 0 30px 30px;
    background-color: #f4f4f4;
    button {
      margin-right: 20px;
    }
  }
}