@import 'fonts.scss';

.outdoorMonitor {
  background-color: $white2;

  .firstColumn {
    min-width: 186px;
    max-width: 386px;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .date {
    min-width: 100px;
  }

  .titleArea {
    color: $black;
    height: 84px;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
  }

  .contentArea {
    height: calc(100% - 124px);
    display: flex;
    flex-direction: column;

    .dimensionSelectArea {
      border-top: 1px solid $super-light;
      background-color: $white;
      padding-top: 40px;

      .dayRange {
        display: flex;
        flex-direction: column;
        & > div {
          margin-bottom: 10px;
          width: fit-content;
        }
        span.hint,
        span.errorHint {
          display: block;
          @include base-font;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.17;
          color: #98969d;
          margin-top: 2px;
          width: 100%;
        }
        span.errorHint {
          color: $danger;
        }
      }
    }

    .chartArea {
      border-top: 1px solid $super-light;
      background-color: $white;
      .chart {
        border: 1px solid $super-light;
        margin: 30px;
        padding: 20px 0;
      }
    }

    .filterArea {
      background-color: $white;
      flex: 0 0 66px;
      border-top: 1px solid $super-light;
      border-bottom: 1px solid $super-light;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      :global(.search-bar) {
        width: 280px;
      }
    }
  }
}
