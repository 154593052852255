@import 'colors.scss';
@import 'fonts.scss';

.emailForm {
  .formTitle {
    height: 40px;
    margin-top: 140px;
    margin-bottom: 6px;
    margin-left: -10px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin: auto;
    width: 450px;
    background-color: $white3;
    .iconGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
    .subtitle {
      font-size: 20px;
      font-weight: 500;
    }
    .hints {
      @include base-font;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.57;
      color: $dark;
      white-space: pre-line;
      width: 320px;
    }
    .inputGroup {
      display: flex;
      flex-direction: column;
      &.withError {
        input {
          border: 1px solid $danger;
        }
      }
      width: 320px;
      margin-bottom: 20px;
      position: relative;
      label {
        width: fit-content;
        height: 14px;
        @include base-font;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.17;
        color: $theme1;
        margin: 0;
        margin-bottom: 8px;
        vertical-align: top;
      }
      input {
        line-height: 24px;
        width: 320px;
        height: 34px;
        box-sizing: border-box;
      }
      .inputError {
        @include base-font;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.17;
        color: $danger;
        width: 100%;
        position: absolute;
        bottom: -15px;
      }
    }
    button {
      margin-top: 30px;
      padding: 9px 30px !important;
      width: fit-content;
    }
    .underscoreText {
      margin-top: 20px;
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      text-align: center;
      color: $theme1;
      text-decoration: underline;
      cursor: pointer;
    }
    .sentMailTitle {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      color: #212529;
      margin-bottom: 10px;
    }
    .sentMailMessage {
      @include base-font;
      font-size: 14px;
      line-height: 1.57;
      text-align: center;
      color: $dark;
      width: 320px;
    }
  }
}

@media (max-width: 1024px) {
  .emailForm {
    flex-grow: 1;
    .formTitle {
      margin-top: 60px
    }
    form {
      width: 100%;
      .inputGroup {
        width: 500px;
        input {
          width: 500px;
        }
      }
      .sentMailMessage, .hints {
        width: 500px;
      }
    }
  }
}

@media (max-width: 640px) {
  .emailForm {
    form {
      .inputGroup {
        width: 311px;
        input {
          width: 311px;
        }
      }
      .sentMailMessage, .hints {
        width: 311px;
      }
    }
  }
}