@import 'colors.scss';
.searchBarContainer {
    border-bottom: 1px solid $super-light;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    > :global(.search-bar) {
        width: 370px;
    }
}

.accountListContainer td {
    vertical-align: middle;
}