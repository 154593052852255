@import 'fonts.scss';

.app-footer {
  background-color: $theme2;
  width: 100%;
  @include base-font;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: $white;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 0;
  .app-footer-privacy-policy {
    font-size: 10px;
    margin: auto;
  }
}
