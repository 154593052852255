@import 'fonts';

.lifetimeBudgetError {
  :global(.errorTip), a {
    min-height: 14px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    width: 100%;
    display: block;
  }
  a {
    margin-top: 2px;
  }
  :global(.errorTip) {
    color: $danger;
  }
}