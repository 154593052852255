@import './basicForm.scss';

.formContent {
  .videoInput {
    border: none !important;
  }

  .logoInput {
    width: 200px;
    height: 200px;
  }
}
