@import 'colors.scss';

.dropdownToggle {
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  min-width: 100px;
  padding: 8px 8px 8px 12px;
  font-family: NotoSansSC, "Noto Sans SC", sans-serif;
  font-size: 13.5px;
  font-weight: normal;
  color: $black;
  &.open,
  &:hover {
    cursor: pointer;
    background-color: $white2;
  }
  &::after {
    content: none;
  }
  &.isSelected {
    color: $theme1;
  }
}
.dropdownMenu {
  max-height: 500px;
  overflow-y: auto;
}
.filterItem {

  .itemContainer {
    height: 100%;
    display: flex;
    align-items: center;
  
    .arrowContainer {
      padding-left: 14px;
      cursor: pointer;
  
      .arrow {
        width: 6px;
        height: 6px;
        border: solid $dark;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 1px;
        margin-right: 8px;
        margin-left: 2px;
        cursor: pointer;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-bottom: 2px;
      }
    }

    .dropdownItem {
      padding-left: 0rem;
    }
    &:hover {
      background-color: $white2;
    }
    &.isSelected {
      color: $theme1;
      background-color: $white2;
    }
  }

  .options {
    display: none;
    
    .dropdownItem {
      width: 100%;
      padding-left: 44px;
    }
  }
}
.filterItem.open {

  .itemContainer {

    .arrowContainer {
      .arrow {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: 4px;
      }
    }
  }

  .options {
    display: block;
  }
}
.dropdownItem {
  display: flex;
  align-items: center;
  width: 300px;
  height: 34px;
  &.isSelected {
    color: $theme1;
    background-color: $white2;
  }
  &:hover {
    background-color: $white2;
  }
  &:active {
    color: #212529;
  }
  &:focus:not(.isSelected):not(:hover) {
    background-color: $white;
  }
}
