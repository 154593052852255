@import 'colors.scss';

.separationLine {
  width: 100%;
  height: 1px;
  border-top: 1px solid $super-light;
  &.bold {
    height: 2px;
    border: 1px solid $super-light;
  }
}
