@import 'fonts.scss';

.nativeCreativeDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: fit-content;
  position: relative;
  padding-bottom: 50px;
  .toggleButtons {
    overflow: auto;
    height: fit-content;
    z-index: 1;
    padding-top: 20px;
  }
  .nativeDetail, .nativeBannerDetail {
    height: 0px;
    overflow: hidden;
    &.show {
      height: fit-content;
    }
  }
  .buttonArea {
    span {
      margin-left: 15px;
      margin-right: 15px;
      @include base-font;
      color: $dark;
      cursor: pointer;
      &.active {
        color: $theme1;
      }
    }
  }
}
