@import 'fonts.scss';
@import 'components.scss';

.currencyRateList {
  $topAreaHeight: 77px;
  min-height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include tabs;
    :global(.nav) {
      padding-top: 30px;
      height: 72px;
    }
    > div:last-child {
      flex-grow: 1;
    }
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  th:first-child,
  td:first-child {
    padding-left: 60px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
  }
  .titleArea {
    color: $black;
    height: 84px;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
  .filterArea {
    padding-left: 30px;
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    height: $topAreaHeight;
    background-color: $white;
    :global(.input-group) {
      width: auto;
      margin-right: 20px;
    }
    .createBtn {
      color: $white;
    }
  }
}

.tableContainer {
  width: 100%;
  max-height: 250px;
  overflow: auto;
  border: 1px solid $super-light;
  :global(.table) {
    margin-bottom: 0;
  }
}
