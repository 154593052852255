@import 'fonts.scss';

@mixin pad {
  @media (max-width: 1300px) {
    @content;
  }
}
.storySpacePreviewIcon {
  width: 12px;
  height: 14px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $super-light;
}

.storySpacePreviewIcon:hover {
  color: $theme5;
  cursor: pointer;
}

.storySpacePreview {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 3;
  background-color: transparent;
  width: 300px;
  height: 540px;

  @include pad {
    position: inherit;
  }
}

.storySpacePreviewPosition {
  right: 30%;
  bottom: -220px;
}

.mobilePhoneTemplate {
  width: 230px;
  height: 474px;
  border: 40px solid #ddd;
  border-width: 45px 7px;
  border-radius: 40px;
  margin: 20px auto;
  overflow: hidden;
  box-shadow: 0px 3px 0 #bbb, 0px 4px 0 #bbb, 0px 5px 0 #bbb, 0px 7px 0 #bbb, 0px 10px 20px #666;

  .storySpaceTemplate {
    border: 1px solid $black;
    width: 100%;
    height: 100%;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 2.5px dashed $white;
      width: 100%;
      height: 14%;
      background-color: $theme5;
      color: $white;

      .storyLogo {
        border: 2px solid $black;
        border-radius: 50%;
        margin: 10px;
        margin-right: auto;
        width: 36px;
        height: 36px;
      }

      .storyTextOrBtn {
        font-size: 12px;
        padding: 5px;
        padding-top: 10px;
        height: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 72%;
      background-color: $success;

      .storyRatioInfo {
        font-size: 55px;
        font-weight: 900;
        height: auto;
      }

      .storySizeInfo {
        font-size: 12px;
        height: auto;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top: 2.5px dashed $white;
      width: 100%;
      height: 14%;
      background-color: $theme5;
      color: $white;

      .storyInfoLink {
        font-size: 12px;
        padding: 5px;
        height: auto;
      }
    }
  }
}