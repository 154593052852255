@import 'fonts.scss';

.hint, .hint a {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  margin-top: 2px;
  width: 100%;
  span {
    color: darken($warning, 15%);
    font-weight: bold;
  }
}
.hint {
  display: block;
  color: $warning;
  width: 424px;
}
