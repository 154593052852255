@import 'fonts.scss';

.overlay {
  max-width: 280px;
  width: max-content;
  user-select: text;
  @include base-font;
  color: $white;
  font-size: 12px;
  opacity: 0.9;
  background-color: $black;
  border-radius: 0.25rem;
}

.overlayContent {
  padding: 0px 10px;
  margin-top: 10px;
  &::-webkit-scrollbar {
    width: 3px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5) !important;
  }
  &::-webkit-scrollbar-track {
    border: none !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(255, 255, 255) !important;
  }
}

.scrollHint {
  position: absolute;
  bottom: 1px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}