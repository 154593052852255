@import '../../../colors.scss';
@import '../../../fonts.scss';

.slider {
  display: inline-block;
  position: relative;

  .displayArea {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .indicators {
    height: 28px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    .indicator {
      border: solid 1px #98969d;
      background-color: $white;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      cursor: pointer;
      margin: 2px;

      &.active {
        background-color: $light;
      }
    }
  }

  .center {
    overflow: hidden;
    display: flex;

    .centerContainer {
      display: inline-flex;
      flex-direction: row;
    }
  }

  .normalChild {
    display: flex;
    justify-content: center;
    align-items: center;  
  }

  .controlBtn {
    width: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child {
      margin-right: 15px;
    }
    &:last-child {
      margin-left: 15px;
    }
    &.bottom {
      z-index: 1;
      position: absolute;
      bottom: 0;

      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }
  }

  .prev {
    border: solid black;
    border-width: 0 2px 2px 0;
    padding: 6px;
    transform: rotate(135deg);
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }

  .next {
    border: solid black;
    border-width: 0 2px 2px 0;
    padding: 6px;
    transform: rotate(-45deg);
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}