@import 'fonts.scss';

.reportGran {
  display: flex;
  flex-direction: column;
  &>div:first-child {
    margin-bottom: 10px;
    width: fit-content;
  }
  .timezoneSelection {
    margin-top: 10px;
    width: 100px;
  }
  span.hint, span.errorHint {
    display: block;
    @include base-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
  }
  span.errorHint {
    color: $danger;
  }
}