@import 'fonts.scss';
.accountList {
    height: 100%;
    .email {
        min-width: 300px;
        width: 30%;
        padding-right: 24px;
    }
    .name,
    .status {
        min-width: 200px;
        width: 20%;
        padding-right: 24px;
    }
    .name {
        padding-left: 64px;
    }
    .isAdmin, .isGoJekAccountManager, .pending {
        white-space: nowrap;
        padding-right: 24px;
    }
    .nameCell {
        .name {
            @include base-font;
            width: 172px;
            font-size: 14px;
            line-height: 1.57;
            color: $theme1;
            padding: 0;
        }
        .id {
            @include base-font;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.2;
            color: $dark;
            padding: 4px 0px;
        }
    }
}

.sudoNameCell {
    display: flex;
    align-items: center;
    .name {
        @include base-font;
        width: 172px;
        font-size: 14px;
        line-height: 1.57;
        color: $theme1;
        padding: 0;
    }
    .id {
        @include base-font;
        font-size: 10px;
        font-weight: 500;
        line-height: 1.2;
        color: $dark;
        padding: 4px 0px;
    }
}