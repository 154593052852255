@import 'colors';

.oneForAllDisplayPreview {
  .previewSection {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin-right: 30px;
      width: fit-content;
      height: fit-content;
    }
    margin-bottom: 20px;
  }
}