@import 'colors.scss';
@import 'fonts.scss';

.creativeBasicForm {
  .formLabel {
    height: 100%;
    display: flex;
    align-items: center;
    @include base-font;
    color: $dark;
  }

  label.landingPageUrl {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $dark;
    margin: 0;
    display: flex;
    align-items: center;
    flex-grow: 1;
    word-break: break-all;
    width: 1%;
    text-align: left;
  }

  .timeSwitch {
    height: 36px;
    display: flex;
    align-items: center;
  }

  .landingPageUrl + :global(.input-group-append) {
    align-self: flex-start;
    span {
      border: none !important;
      padding: 0;
    }
  }

  .urlField + :global(.input-group-append) {
    span {
      border: none !important;
      cursor: pointer;
      > svg {
        color: $dark;
      }
    }
  }

  .editLandingPageUrlBtn {
    height: 36px;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 20px;
    line-height: 34px;
    border-radius: 2px;
    border: 1px solid $dark;
    background-color: $white;
    cursor: pointer;
    flex-shrink: 0;
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: $dark;
  }

  .loadingCircleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 29px;
    width: 33px;
    .loadingCircle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 2px solid $dark;
      animation-name: roller;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      border-left-color: transparent;
    }
  }

  @keyframes roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .macroInstructionBtn {
    font-size: 12px;
    line-height: 1.5;
    padding-top: 7px;
    svg {
      font-size: 14px;
      margin-left: 5px;
    }
    color: $primary1;
    cursor: pointer;
  }
}
