@import './basicForm.scss';

.sizeInstructionBtn {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.17;
  color: $primary1;
  cursor: pointer;
  svg {
    font-size: 14px;
    color: $primary1;
    margin-left: 3px;
  }
}