@import './nativeForm.module.scss';

.imageInput {
  width: 200px;
  height: 200px;
}

.rectImageInput {
  width: 200px;
  height: 105px;
}

.useOptionImage {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  label {
    color: $dark;
    margin-right: 8px;
  }
}

.storyImgInput {
  width: 180px;
  height: 320px;
}

.emptyFileInput {
  border-style: dashed;
}
