

.trackingCode {
  color: #676767 !important;
  cursor: default;
}

:global(textarea.form-control).originTrackingCode {
  height: 100px;
}

.select {
  width: auto;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  button {
    margin-left: 15px;
  }
}