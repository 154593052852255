@import 'fonts.scss';

.audioPreview {
  width: fit-content;
  .audioContent {
    box-sizing: content-box;
    border: 1px dashed $super-light;
    background-color: $white;
    cursor: pointer;
  }
}

.audioPreview {
  & + span {
    @include base-font;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.17;
    color: $light;
    &:global(.errorTip) {
      color: $danger;
      height: auto;
    }
  }
}

.showSolidBorder {
  .audioContent {
    border: 1px solid $super-light;
  }

  &.showErrorBorder {
    .audioContent {
      border: 1px solid $danger;
    }
  }
}

.showErrorBorder {
  .audioContent {
    border-color: $danger;
  }
}
