@import 'fonts';

.adAccountOption {
  .adAccountName {
    @include base-font;
    font-size: 12px;
    color: $black;
    height: 16px;
  }
  .adAccountId {
    @include base-font;
    font-size: 10px;
    font-weight: 500;
    color: $dark;
  }
}

.adAccountSingleValue {
  .adAccountName {
    @include base-font;
    font-size: 12px;
    color: $black;
    line-height: 14px;
  }
  .adAccountId {
    @include base-font;
    font-size: 10px;
    font-weight: 500;
    color: $dark;
    line-height: 12px;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  &.adAccountOption {
    padding-left: 12px;
    .adAccountName {
      color: $light;
    }
    .adAccountId {
      color: $super-light;
    }
  }
}