@import 'fonts.scss';

.savedTAManagement {
  padding: 30px;
  :global(.row) {
    margin-left: 0px;
    margin-right: 0px;
  }
  .taDetail {
    border-top: 1px solid $super-light;
    padding-top: 20px;
  }
  .outDateHint {
    @include base-font;
    color: $danger;
    padding-bottom: 10px;
  }
}