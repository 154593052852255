@import './nativeForm.module.scss';

.imageInput {
  max-width: 300px;
  img {
    max-width: 300px;
    max-height: 400px;
  }
  &.isStory {
    max-width: 180px;
  }
}

.videoInput {
  border: none !important;
}

.storyVideoInput {
  width: 180px;
  height: 320px;
  border: none !important;
}
