.comboCreativeDetailContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .comboCreativeDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 300px;
    overflow: hidden;
    video {
      width: 300px;
    }
    > div {
      height: 250px;
    }
  }
}