@import 'fonts.scss';
@import 'colors.scss';

.conversionListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  td {
    vertical-align: middle;
  }
  .operationArea {
    flex-shrink: 0;
    background-color: $white;
    border-bottom: 1px solid $super-light;
    position: relative;
    display: flex;
    align-items: center;
    height: 66px;
    a {
      @include base-font;
      margin-left: 30px;
      height: 34px;
      border-radius: 2px;
      background-color: $theme1;
      color: $white;
      padding: 10px 30px;
      display: flex;
      align-items: center;
    }
    :global(.search-bar) {
      width: 280px;
      margin: 0px;
      position: absolute;
      right: 40px;
    }
  }
  .conversionList {
    flex-grow: 1;
  }
  .nameCell {
    span.conversionName, a.conversionName {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
    }
    span.conversionName {
      color: $dark;
    }
    a.conversionName {
      color: $theme1;
    }
    .conversionId {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
  .nameIdColumn {
    min-width: 350px;
    width: 50%;
    padding-left: 64px;
    padding-right: 24px;
    word-break: break-all;
  }
  .typeColumn {
    width: 50%;
    min-width: 200px;
    padding-right: 24px;
  }
  .editBtnsColumn {
    min-width: 0;
    max-width: 0;
    width: 0;
    padding: 0px;
    .floatingEditArea {
      display: none;
    }
  }
  tr:hover {
    .floatingEditArea {
      display: flex;
    }
  }
}