@import '../../fonts.scss';
@import '../../colors.scss';

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  min-height: 100%;

  .textArea {
    @include base-font;
    width: 500px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    text-align: center;
    color: $black;
    margin: 30px 0px;
  }
}