@import 'fonts.scss';

.imageCreativeSizeHint {
  width: 462px;
  .title {
    border-bottom: 1px solid $super-light;
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 19px; 
    padding-right: 22px;
    span {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      color: $black;
    }
    svg {
      font-size: 14px;
      color: $dark;
      cursor: pointer;
    }
  }

  .imageSize {
    padding-left: 39px;
    .imageSizeContent {
      padding-top: 20px;
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      .imageSizeInstance {
        cursor: pointer;
        div {
          width: 15px;
          height: 15px;
          background-color: $dark;
          display: inline-block;
          margin-right: 15px;
        }
        display: flex;
        align-items: center;
        width: 106px;
        margin-right: 23px;
        padding-bottom: 23px;
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $dark;
      }
    }
    padding-bottom: 22px;
  }
}

.sizeDetailModal {
  :global(.modal-content) {
    border: none;
    background: transparent;
    box-shadow: none;
    position: relative;
    pointer-events: none;
  }
}

.sizeBackground {
  border: 1px solid $dark;
  background-color: $white;
}

.sizeHint {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  color: $dark;
}