@import 'fonts.scss';

.chooseCreateMethodStep {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    @include base-font;
    margin-top: 40px;
    margin-left: 30px;
    margin-bottom: 10px;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
  }

  .buttonArea {
    padding: 20px;
    min-height: 558px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    .buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-width: 660px;
      max-width: 660px;
      .queryMethodBtn {
        cursor: pointer;
        min-width: 300px;
        min-height: 204px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid $super-light;
        background-color: $white;
        margin: 15px;
        @include base-font;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.18;
        text-align: center;
        color: $theme1;
        &.selected {
          border: none;
          background-color: $white2;
          pointer-events: none;
        }
        img {
          margin-bottom: 20px;
        }
      }
    }
  }
}
