@import 'components';

.agencyFrom {
  @include tab-form-content;
  .fieldContent {
    overflow: visible;
  }
}

.addonPropsField {
  label {
    padding-left: 0;
    padding-right: 0;
  }
}

.autoHeight {
  > div {
    height: auto !important;
  }
}

.reportLogoPlaceholder {
  width: 152px;
  height: 152px;
}

.autoWithSelect {
  width: auto;
}

.subTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  label {
    text-align: left !important;
    padding-left: 0 !important;
  }
  span {
    margin-right: 10px;
  }
}

.manualForm {
  :global(.input-group) {
    width: 150px;
  }
  label {
    text-align: left !important;
    padding-left: 0 !important;
  }
}
.formLabel {
  text-align: left;
  color: $dark;
  height: 35px;
  line-height: 35px;
}
.row {
  position: relative;
  button {
    position: absolute;
    top: 0px;
    left: 150px;
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: $dark;
    height: 35px;
    margin-left: 20px;
  }
}

.bidPriceSettings {
  margin-top: 5px;
}

.campaignTypesInfo {
  padding: 7px 15px 7px 10px;
  font-family: NotoSansSC, 'Noto Sans SC', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  word-spacing: 1px;
  color: $dark;
}