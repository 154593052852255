@import 'fonts';

.status {
  width: max-content;
  font-size: 12px;
  color: #fff;
  height: 18px;
  border-radius: 12px;
  line-height: 18px;
  box-sizing: content-box;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  background-color: $theme1;
  white-space: nowrap;
  &.pointer {
    cursor: pointer;
  }
  &.black {
    background-color: $black;
  }
  &.info {
    background-color: $info;
  }
  &.warning {
    background-color: $warning;
  }
  &.danger {
    background-color: $danger;
  }
  &.success {
    background-color: $success;
  }
  &.deep-warning {
    background-color: $deep-warning;
  }
  &.dark {
    background-color: $dark;
  }
  &.light {
    background-color: $light;
  }
  &.theme1 {
    background-color: $theme1;
  }
  &.theme3 {
    background-color: $theme3;
  }
  &.white {
    color: $dark;
    border: 1px solid $dark;
    background-color: $white;
  }
  &.whiteTheme4 {
    color: $theme4;
    border: 1px solid $theme4;
    background-color: $white;
  }
}
