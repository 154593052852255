@import '../../../fonts.scss';
@import '../../../colors.scss';
@import 'components.scss';

.productListArea {
  $topAreaHeight: 77px;
  background-color: $white3;
  // height: calc(100% - 168px);
  height: 100%;
  display: flex;
  flex-direction: column;
  
  :global(.nav-link) {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .productListTitleBar {
    height: $topAreaHeight;
    border: 1px solid $super-light;
    background-color: $white;
    border-left: none;
    border-right: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
  }
  .contentArea {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // background-color: $white2;
    // > div {
    //   height: 100%;
    // }

    @include tabs;
    :global(.nav) {
      padding-top: 30px;
      height: 72px;
    }
  }
  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
    &.imgLink {
      padding-left: 0px;
    }
  }
  thead th,
  th:first-child,
  td:first-child {
    padding-left: 20px;
  }
  .selectCheckBox {
    min-width: 48px;
    height: auto;
    input {
      display: none;
    }
    input + label {
      margin-right: 0;
    }
    input:checked + label::before,
    input:indeterminate + label::before {
      background-color: $theme1;
      border: 1px solid $theme1;
    }
    input:indeterminate + label::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 13px;
      width: 1px;
      height: 1px;
      box-shadow: 1px 0 0 $white, 2px 0 0 $white, 3px 0 0 $white,
        4px 0 0 $white, 5px 0 0 $white, 6px 0 0 $white, 7px 0 0 $white,
        8px 0 0 $white;
    }
  }

  .nameHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .nameCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .name {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
  .imgCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .imgPreview {
      min-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 140px;
      }
    }
  }
  tr:hover {
    td {
      background-color: $white2;
    }
  }

  .productId {
    min-width: 250px;
    max-width: 360px;
  }
  .genre,
  .price {
    white-space: nowrap;
  }
  table {
    table-layout: fixed;
  }
}