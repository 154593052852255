@import 'colors.scss';
@import 'fonts.scss';

.advertiserFormContainer {
  height: 100%;
  background-color: $white2;

  .titleArea  {
    background-color: $white;
    height: 120px;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
  }

  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }

  form {
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .buttonArea {
    button {
      margin-left: 20px;
    }
    button:first-child {
      margin-left: 30px;
    }

    padding-bottom: 60px;
  }

  :global(.col-sm-3) {
    label {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
    }
  }

  :global(.col-sm-9) {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $black;
    
    > input, .categories, textarea {
      max-width: 424px;
      min-width: 424px;
    }
    
    textarea {
      height: 100px;
    }
  }
}