@import 'fonts.scss';

.doctors {
  width: 100%;
  height: 100%;
  background-color: $white2;
  overflow-x: auto;

  th:first-child, td:first-child {
    padding-left: 60px;
  }
  tbody > tr {
    cursor: pointer;
  }
  td {
    vertical-align: middle;
  }

  .campaignId, .creativeId {
    width: 150px;
  }
  .container {
    min-width: 1000px;
    margin: 0;
  }
  
  :global(.form-group.row) {
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 auto;
  }

  .titleArea {
    color: $black;
    height: 84px;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    .title {
      @include base-font;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
  }

  .contentArea {
    border-top: 1px solid $super-light;
    border-bottom: 1px solid $super-light;
    height: calc(100% - 124px);
    display: flex;
    flex-direction: column;
  }
  
  .inputArea {
    padding-top: 40px;
    background-color: $white;
  }

  .checkBtnContainer {
    flex: 0 0 auto;
    padding-bottom: 30px;
    margin-bottom: 0;
  }

  .chartArea {
    border-top: 1px solid $super-light;
    background-color: $white;
    padding: 20px 10%;
    padding-left: 5%;
    min-width: 1000px;
  }
}
