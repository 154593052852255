@import 'colors.scss';

.label {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: $dark;
}

.currencyLabel {
  @extend .label;
  margin-right: 5px;
}

legend>span {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: $black;
  margin-left: 30px;
}

.budgetPlanRadio {
  width: auto;
  :global(.form-check-label) {
    margin-right: 5px;
  }
}

.priceLabel {
  :global(.input-group-text) {
    padding-left: 0px;
    padding-right: 5px;
  }
}

.clickUrlInput {
  :global(.input-group) {
    flex-wrap: nowrap;
  }
}

.component {
  background-color: $white;
  box-shadow: 5px 2px 5px 0 rgba(97, 95, 103, 0.5);
  padding: 40px 30px;

  .messageHint {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    > span {
      margin-top: 4px;
    }

    .loadingCircle {
      margin-top: 4px;
      margin-right: 4px;
      border-color: $light;
      border-left-color: transparent;
    }
  }

  :global {
    .form-check {
      padding-left: 0;
    }
  }
  :global(.react-tagsinput-input) {
    border: none;
    flex-grow: 1;
  }

  :global(.react-tagsinput-tag) {
    flex-shrink: 0;
  }

  :global(.react-tagsinput)  > span {
    display: flex;
    flex-wrap: wrap;
  }

  :global(.react-tagsinput-input:hover) {
    border: none; 
  }
}

.radio {
  padding: 6px 15px;
}

.marginBottom7 {
  margin-bottom: 7px;
}

.marginBottom0 {
  margin-bottom: 0px;
}

.dailyOption,
.dailyBudgetInput {
  margin-bottom: 14px;
}

.dailyBudgetInput {
  margin-top: -10px;
}

.videoAdviewObjectiveAppend {
  margin-top: 6px;
}

.lastVideoAdviewObjective {
  margin-bottom: 1rem;
  margin-top: -5px;
  :global(.input-group) {
    margin-top: 15px;
  }
}

.videoAdMetricEvent {
  border-radius: 0;
  width: 184px;

  :global {
    .react-select__control {
      border-radius: 0;
    }
  }
}

.priceModel {
  position: relative;
  &.componentWithTips~span.hoverTip {
    left: 445px;
  }
  text-align: left;
}

.componentWithTips~span.hint {
  display: block;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #98969d;
  margin-top: 2px;
  width: 100%;
}

.orderPriceSwitch {
  display: flex;
  align-items: center;
  > :global(.react-toggle) {
    width: 70px;
    margin-top: 0px;
  }
}

.componentWithTips~span.hoverTip {
  display: none;
  position: absolute;
  left: 380px;
  top: 10px;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  color: $theme1;
}

.componentWithTips:hover~span.hoverTip {
  display: inline-block;
}

.budgetError {
  :global(.errorTip), a {
    min-height: 14px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    width: 100%;
    display: block;
  }
  a {
    margin-top: 2px;
  }
  :global(.errorTip) {
    color: $danger;
  }
}

.selectedClickUrlFormGroup {

  :global(.form-group) {
    align-items: center;
    margin-bottom: inherit;
  }

  .clickUrlContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: visible;
    min-width: 100%;
    border-radius: 5px;
  
    .clickUrlOpenButton {
      width: fit-content;
      margin: 5px 0px;
      padding: 5px 0px;
      background-color: $white3;
      button {
        color: #98969d;
        &:hover {
          color: $super-light;
        }
        > svg {
          margin-right: 5px;
        }
      }
      &.disabled {
        button {
          cursor: not-allowed;
          &:hover {
            color: #98969d;
          }
        }
      }
    }
  }
}
