@import 'fonts.scss';
@import 'colors.scss';

.container {
  background-color: $white2;
  min-height: 100%;
  height: 1px;
  .titleArea {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 30px;

    .agencyOptions {
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      .title {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $dark;
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
  .advertiserListArea {
    background-color: $white2;
    height: calc(100% - 168px);
    > div {
      height: 100%;
    }
  }
}