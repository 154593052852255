@import 'fonts.scss';

.operationArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
  flex-wrap: wrap;
  padding: 20px 30px;
  min-height: 80px;
  background-color: $white;
  position: relative;

  :global(.search-bar) {
    width: 370px;
    margin: 0px;
  }
}