@import 'fonts.scss';
@import 'colors.scss';
.segmentDetailContainer {
  background-color: $white2;
  min-height: 100%;
  height: 1px;
  display: flex;
  flex-direction: column;
  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    .buttonArea {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 30px;
      flex-shrink: 0;
      >input {
        display: none;
      }
      > div, > a {
          cursor: pointer;
          margin-left: 30px;
          @include base-font;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          color: $theme1;
          &:hover {
            color: $theme2;
          }
      }
    }
  }
  .basic {
    display: flex;
    flex-wrap: wrap;
  }
  .segmentName {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
    padding: 20px 0px 11px 0px;
  }
  .field {
    flex-grow: 0;
    min-width: 15%;
    padding: 0;
    padding-right: 35px;
    margin: 10px 0;
  }
  .fieldLabel,
  .fieldValue {
    margin: 0;
    padding: 0;
    max-width: none;
    label {
      @include base-font;
      margin: 0;
      font-weight: 500;
      line-height: 1.17;
    }
  }
  .fieldLabel {
    font-size: 12px;
    color: $light;
  }
  .fieldValue {
    font-size: 14px;
    color: $dark;
    line-height: 1.57;
    margin-top: 2px;
    word-break: break-all;
  }
  .sepLine {
    height: 1px;
    border-top: 1px solid $super-light;
  }

  .segmentDetail {
    padding: 0px 30px 10px 30px;
    margin-bottom: 28px;
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
  }
}

:global {
  .tooltip {
    &[id*="importAudiencesTip-"] { 
      .tooltip-inner {
        font-size: 12px;
        font-weight: 500;
        @include base-font;
      }
    }
  }
}