@import 'fonts.scss';
@import 'colors.scss';

.creativeBindManagePage {
  .buttonArea {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  :global(.react-bs-table-no-data) {
    text-align: center;
  }

  .id {
    max-width: 150px;
    min-width: 150px;
    width: 150px;
    @include base-font;
    padding-left: 20px;
    div {
      display: flex;
      align-items: center;
      div {
        label {
          width: 14px;
          margin-right: 20px;
        }
      }
    }
  }

  .name {
    max-width: 250px;
    min-width: 250px;
    width: 250px;
    word-break: break-all;
    @include base-font;
  }

  .isActive {
    max-width: 180px;
    min-width: 180px;
    width: 180px;
    @include base-font;
  }

  thead {
    background-color: $white3;
    th {
      outline: none;
      height: 46px;
      &.id {
        padding-left: 20px;  
      }
    }
  }

  .tableContainer {
    max-height: 300px;
    overflow: auto;
    box-shadow: 0 1px 2px 0 rgba(97, 95, 103, 0.5);
  }

  table {
    margin: 0;
    box-shadow: none;
  }
}