@import 'fonts.scss';

.container {
  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 40px 0 16px 30px;
  }

  .content {
    background-color: $white;
    padding: 30px;
    section {
      min-width: 300px;
      position: relative;
    }
    .basicSummary {
      display: flex;
      justify-content: space-between;
      .estimateData {
        @include base-font;
        // max-width: 245px;
        max-width: 288px;
        min-width: 200px;
        font-size: 12px;
        color: $light;
        border: 1px solid $dark;
        height: fit-content;
        padding: 30px;
        margin: 40px 150px auto 20px;
        .estimateDataField {
          color: $black;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.2;
          &:first-child {
            margin-bottom: 15px;
          }
          .estimateLowHint {
            color: $danger;
            div:nth-child(1) {
              font-size: 20px;
              font-weight: bold;
            }
            div:nth-child(2) {
              font-size: 12px;
              margin-top: 2px;
            }
          }
        }
        .estimateDataTitle  {
          color: $light;
          font-size: 12px;
          margin-bottom: 2px;
          svg {
            margin-left: 5px;
            color: $super-light;
          }
        }
        .estimateLimitation {
          margin-top: 15px;
          .estimateLimitationDesc {
            margin-bottom: 5px;
          }
          ul {
            padding-left: 20px;
            margin-bottom: 0;
          }
          li {
            overflow-wrap: break-word;
          }
        }

        .saveHint {
          @include base-font;
          font-size: 12px;
          line-height: 1.42;
          color: $dark;
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }
    }
    .categoryTitle {
      @include base-font;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.18;
      color: $dark;
      margin: 10px 0 10px 0;
      display: flex;
      align-items: center;

      .backBtn  {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: #5845a5;
        cursor: pointer;
        margin-left: 30px;
      }
    }

    .sepLine {
      height: 1px;
      border-top: solid 1px $super-light;
    }
  }

  .buttonArea {
    margin: 60px 0px 84px 30px;
    button {
      margin-right: 20px;
    }
  }
}

:global {
  .tooltip {
    &#uniqueUser, &#impression {
      .tooltip-inner {
        text-align: left;
        @include base-font;
        font-size: 12px;
        pointer-events: none;
      }
    }
  }
}