@import 'fonts.scss';
@import 'colors.scss';

.accountForm {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 1 1 auto;

  h3 {
    background-color: $white;
    height: 120px;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px 30px 0px 30px;
    margin: 0;
  }

  .titleBottomLine {
    width: 100%;
    height: 2px;

    border: 1px solid $super-light;
    background-color: $white;
  }

  .formContent {
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;

    .required::before {
      content: '* ';
      color: $danger;
    }

    .alignLeft {
      text-align: left;
    }

    .disabled {
      color: $light;
    }

    input[type="checkbox"] {
      margin: auto 0;
    }

    span {
      margin: auto 5px;
    }

    .fieldValue {
      display: flex;
      flex-direction: column;
      height: 100%;
      input {
        max-width: 460px;
        min-width: 460px;
      }
      .errorMessage {
        color: $danger;
        margin-top: 2px;
        font-size: 12px;
        line-height: 14px;
      }
    }
    .agencyField {
      input {
        max-width: fit-content;
        min-width: fit-content;
      }
    }
  }
  .formButtons {
    margin-left: 30px;
    button, a {
      margin-left: 20px;
    }

    padding-bottom: 60px;
  }

  &.isModalContent {
    .title {
      display: none;
    }
    .titleBottomLine {
      display: none;
    }
    .formContent {
      margin: 0;
      padding-top: 30px;
      box-shadow: none;
    }
    .formButtons {
      display: none;
    }
  }

  .roleSelect > div > div {
    width: 460px;
  }

  :global(.row) {
    margin-left: 0;
    margin-right: 0;
  }

  .hints {
    min-height: 14px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    width: 100%;
    margin-top: 2px;
    color: $dark;
    .danger {
      color: $danger;
    }
  }
}