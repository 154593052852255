@import 'components';

.channelGroupDetail {
  height: 100%;
  background-color: $white;
  
  :global(.nav) {
    padding-left: 30px;
    background-color: $white;
    border-bottom: 1px solid $super-light;
  }

  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    padding: 0px 30px;
    border-bottom: 1px solid $super-light;

    .channelGroupName {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
      padding: 20px 0px 11px 0px;
    }

    .buttonArea {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 30px;
      flex-shrink: 0;
      &>div, &>a {
        cursor: pointer;
        margin-left: 30px;
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        color: $theme1;
      }
    }
  }
}