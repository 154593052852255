@import 'fonts.scss';
@import 'components.scss';

.cell {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
}
