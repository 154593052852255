@import 'colors.scss';
@import 'fonts.scss';

.subSegmentFormContainer {
  height: 100%;
  background-color: $white2;

  .title  {
    background-color: $white;
    height: 120px;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px 30px 0px 30px;
  }

  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }

  .formArea {
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 30px;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  :global(.input-group) {
    flex-wrap: nowrap;
  }

  .ruleInput {
    margin-bottom: 10px;
    .errorRuleInput {
      > div > div:first-of-type {
        border: 1px solid $danger;
        border-right: none;
      }
      input {
        border: 1px solid $danger;
      }
    }
  }

  .removeRuleBtn {
    font-size: 14px;
    @include base-font;
    color: $theme2;
    margin-left: 7px;
    margin-top: 2px;
    cursor: pointer;
  }

  .ruleComparatorAdder {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    height: 35px;
    svg {
      font-size: 14px;
      @include base-font;
      color: $theme2;
    }
    :global(.dropdown-toggle) {
      padding: 0;
      padding-left: 6px;
    }
    :global(.dropdown-item:hover) {
      background-color: $theme5;
      color: $black;
    }
    a::after {
      display: none;
    }
  }

  .errorHint {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $danger;
    padding-top: 1px;
    width: 100%;
  }

  .buttonArea {
    button {
      margin-left: 20px;
    }
    button:first-child {
      margin-left: 30px;
    }

    padding-bottom: 60px;
  }

  :global(.col-sm-3) {
    label {
      @include base-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: $black;
    }
  }

  :global(.col-sm-9) {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $black;
    
    > input {
      max-width: 424px;
      min-width: 424px;
    }
  }
}