@import 'fonts.scss';

.storedValueDetail {
  background-color: $white2;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 1px;
  .title {
    background-color: $white;
    flex-shrink: 0;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px;
  }
  .topArea {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 52px;
    border-bottom: 1px solid $super-light;
    margin: 0 30px;
    .buttonArea {
      float: right;
      display: flex;
      & > div {
        cursor: pointer;
        margin-left: 30px;
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $theme1;
      }
      div:hover {
        color: $theme2;
      }
    }
  }
  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }
  .sepLine {
    margin: 0 30px;
    border-top: 1px solid $super-light;
  }
  .detailArea {
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
  .table {
    overflow: hidden;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 28px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    background-color: $white2;
  }
  th:nth-child(1),
  td:nth-child(1) {
    padding-left: 34px;
  }
  .danger {
    color: $danger;
  }
}
