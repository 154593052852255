@import 'colors.scss';

.thirdPartyCreativePreview {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  min-height: 100%;
  align-items: center;
  .toggleButtons {
    overflow: auto;
    height: fit-content;
    z-index: 1;
    padding-top: 20px;
  }
  .previewContainer {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    min-height: 100%;
    align-items: center;
    padding: 30px;
    margin: auto;
    iframe {
      width: 100%;
      height: 100%;
      isolation: isolate;
      z-index: 3;
    }
  }
  .mobileItem {
    display: flex;
    width: 282px;
    height: 568px;
    background-color: $white;
    border-radius: 40px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    ul {
      margin: 80px -5px 0px -5px;
      padding: 0px;
    }
    .mobileVolume {
      li {
        list-style: none;
        width: 3px;
        height: 35px;
        background: #ccc;
        margin: 15px 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    .mobileScreen {
      position: relative;
      background: #262626;
      width: 95%;
      height: 96%;
      border-radius: 30px;
      overflow: hidden;
      margin: 10px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.1);
        width: 100%;
        height: 100%;
        transform: skewX(-5deg) translateX(-50%);
      }
    }
    .mobileSleep {
      li {
        list-style: none;
        width: 3px;
        height: 50px;
        background: #ccc;
        margin: 30px 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  .desktopItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .desktopScreen {
      position: relative;
      background: #000;
      width: 875px;
      height: 500px;
      border-bottom: 30px solid $super-light;
      border-radius: 10px;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        left: -7.5%;
        width: 60%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        transform: skewX(-5deg);
      }
    }
    .desktopHandler {
      width: 70px;
      height: 60px;
      background-color: #bdbdbd;
      transform: perspective(100px) translateY(-15%) rotateX(30deg);
    }
    .desktopBottom {
      width: 180px;
      height: 20px;
      background-color: $super-light;
      transform: translateY(-70%);
    }
  }
}