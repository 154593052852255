@import 'fonts.scss';

.section {
  padding: 0 30px;
}

.titleContainer {
  @include base-font;
  display: flex;
  align-items: center;
  .title {
    margin-right: 10px;
  }
  .sepline {
    border-top: 1px solid $super-light;
    flex-grow: 1;
  }
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: repeat(auto-fill, 1fr);
}
.field {
  flex-grow: 0;
  min-width: 20%;
  padding: 0;
  padding-right: 30px;
  margin: 10px 0;
}
.fieldLabel,
.fieldValue,
.fieldHint {
  margin: 0;
  padding: 0;
  max-width: none;
  label {
    @include base-font;
    margin: 0;
    font-weight: 500;
    line-height: 1.17;
  }
}
.fieldLabel {
  font-size: 12px;
  color: $light;
}
.fieldValue {
  font-size: 14px;
  color: $dark;
  line-height: 1.57;
  margin-top: 2px;
  white-space: pre-line;
  overflow-wrap: break-word;
}
.fieldHint {
  font-size: 12px;
  color: $light;
  line-height: 1.17;
  margin-top: 2px;
}
