@import 'fonts.scss';

.productSetDetail {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .titleArea {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 30px;
  }
  .contentArea {
    flex-grow: 1;
    height: 1px;
    > div {
      height: 100%;
    }
  }

  .modalHint {
    margin-bottom: 10px;
  }
  .campaignUsageInfo {
    margin-top: 10px;
  }
}