@import 'fonts.scss';

.reportGran {
    display: flex;
    flex-direction: column;
    > div {
        width: fit-content;
    }
    > div:first-child {
        margin-bottom: 10px;
    }
}