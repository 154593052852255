@import 'colors.scss';
@import 'fonts.scss';

@mixin unSelect {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

@mixin fontGeneralSetting {
  @include base-font;
  font-weight: normal;
}

.selectItemWithOptions {
  @include unSelect;
  @include fontGeneralSetting;
  font-size: 14px;
  line-height: 1.57;
  min-height: 34px;
  color: $black;

  .categoryContainer {
    height: 34px;
    display: flex;
    align-items: center;
    padding: 6px 14px 6px 0px;
    
    .category {
      flex-grow: 1;
      color: $black;
      cursor: default;
      align-items: center;
  
      &.selectable {
        cursor: pointer;
      }
      display: flex;
      justify-content: space-between;
    }
  
    &:hover {
      background-color: $white2;
    }

    &.selected {
      cursor: default;
      color: $super-light;
      background-color: white;
      .category {
        color: $super-light;
        pointer-events: none;
      }
    }
  
    &.disabled {
      cursor: default;
      color: $super-light;
      .category {
        color: $super-light;
        pointer-events: none;
      }
    }

    .arrowContainer {
      padding-left: 14px;
      cursor: pointer;
    
      .arrow {
        width: 6px;
        height: 6px;
        border: solid $dark;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 1px;
        margin-right: 8px;
        margin-left: 2px;
        cursor: pointer;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin-bottom: 2px;
      }
    }
  }

  .options {
    display: none;
    
    .selectItem {
      user-select: text;
      padding-left: 44px;
      &.readonly {
        cursor: default;
      }
    }
  }
}

.extra {
  @include base-font;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  text-align: right;
  flex-shrink: 0;
  width: 85px;
}

.selectItemWithOptions.open {

  .categoryContainer {
    background-color: $white2;

    .arrowContainer {
      .arrow {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: 4px;
      }
    }

    .category {
      color: #5845a5;
    }

    &.selected {
      .category {
        color: $super-light;
      }
    }
  
    &.disabled {
      .category {
        color: $super-light;
      }
    }
  }

  .options {
    display: block;
  }
}

.selectItem {
  @include unSelect;
  @include fontGeneralSetting;
  font-size: 14px;
  line-height: 1.57;
  min-height: 34px;
  padding: 6px 14px 6px 12px;
  color: $black;
  cursor: pointer;

  &:hover {
    background-color: $white2;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
}

.selectItem.selected {
  pointer-events: none;
  cursor: default;
  color: $super-light;
}

.selectItem.disabled {
  pointer-events: none;
  cursor: default;
  color: $super-light;
}

.selectItem.readonly {
  cursor: default;
}
