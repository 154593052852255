.spacesOfAttribute {
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    padding: 0px 0px 0px 20px;
    margin: 0px;
    list-style-type: decimal;
    li {
      overflow-wrap: anywhere;
    }
  }
}
