@import 'colors.scss';
@import 'fonts.scss';

.campaignAdTypeButton {

  cursor: pointer;
  min-width: 300px;
  min-height: 204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  border: 1px solid $super-light;
  background-color: $white;
  margin: 15px;

  img {
    margin-top:20px;
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  svg {
    margin-top:20px;
    margin-bottom: 10px;
    font-size: 80px;
    color: $theme1;
  }

  .title {
    @include base-font;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.18;
    text-align: center;
    color: $theme1;
    margin-bottom: 10px;
  }

  .description {
    @include base-font;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    text-align: center;
    color: $dark;
    width: 240px;
  }

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(97, 95, 103, 0.5);
  }

  &.selected {
    border: none;
    background-color: $white2;
    pointer-events: none;
  }
}