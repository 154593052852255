@import 'fonts.scss';

.draftTargetForm {
  position: relative;
  :global(.row) {
    margin-right: 0;
    margin-left: 0;
  }
  .closeBtn {
    @include base_font;
    font-size: 21px;
    color: $dark;
    position: absolute;
    right: 30px;
    cursor: pointer;
    z-index: 1;
  }
  .bindHint {
    font-size: 14px;
    color: $light;
  }
}