@import 'fonts.scss';
@import 'colors.scss';

.container {
  background-color: $white2;
  height: 100%;
  display: flex;
  flex-direction: column;
  .titleArea {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    height: max-content;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 30px;
    .filterArea {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .agencyList, .advertiserList {
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      margin-right: 30px;
      .title {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $dark;
        margin-right: 10px;
        margin-top: 5px;
      }
    }
    .agencyList {
      z-index: 5;
    }
  }
  .listArea {
    flex-grow: 1;
    background-color: $white2;
  }
}