@import 'fonts.scss';

.videoCreativeDetailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .videoCreativeDetail {
    max-width: 600px;
    max-height: 400px;
    
    .thirdVideoDetail {
      width: 600px;
      height: 300px;
    }
  }

  .videoPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 140px;
    border: 1px solid $super-light;
    img {
      margin-bottom: 10px;
    }
    span {
      @include base-font;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      text-align: center;
      color: $dark;
    }
  }

  .indicators {
    span {
      margin-left: 15px;
      margin-right: 15px;
      @include base-font;
      color: $dark;
      cursor: pointer;
      &.active {
        color: $theme1;
      }
    }
  }
}