@import 'fonts.scss';

.taInfo {
  position: absolute;
  width: 266px;
  padding: 20px;
  top: 40px;
  right: 69px;
  border: solid 1px $super-light;
  .saveHint {
    @include base-font;
    font-size: 12px;
    line-height: 1.42;
    color: $dark;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}  
.saveModal {
  :global(.modal-dialog) {
    max-width: 600px !important;
  }
  label[for='taName'] {
    padding-left: 0;
    padding-right: 0;
  }

  :global(.form-check) {
    padding-left: 0px;
  }
  :global(.form-check) + .nameInput {
    margin-bottom: 20px;
  }

  input.error {
    border-color: $danger;
  }

  .toReplacedTAName {
    color: $dark;
    .textBlack {
      color: $black;
    }
  }

  .errorMessage {
    color: $danger;
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
  }

  .saveHint {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $black;
    margin-bottom: 20px;
  }

  :global(.form-check-label) {
    @include base-font;
    font-size: 14px;
    line-height: 1.57;
    color: $dark;
    margin-bottom: 10px;
  }

  :global(.row) {
    margin-bottom: 0px;
  }
}