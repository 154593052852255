@import 'colors.scss';

.categoryReason {
  width: 100%;
  height: 100%;
  .categoryReasonTitle {
    height: 30px;
  }
  .categoryList {
    height: calc(100% - 44px);
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    border: 1px solid $super-light;
    padding: 10px;
    .categoryListItem {
      padding-right: 30px;
      width: 150px;
    }
  }
}