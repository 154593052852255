@import '../../../fonts.scss';
@import '../../../colors.scss';

.container {
  $searchBarWidth: 300px;
  position: relative;
  background-color: $white2;
  min-height: 100%;
  height: 1px;
  display: flex;
  flex-direction: column;
  .titleArea {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 30px;
    z-index: 3;

    .filtersFormContainer {
      padding-top: 20px;
    }

    .fieldContent {
      overflow: visible;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: row;

      :global {
        .row {
          margin-right: 15px;
          margin-left: 0px;
          div.col {
            padding-right: 0px;
            padding-left: 0px;
          }
        }
      }
    }

    :global(.search-bar) {
      width: $searchBarWidth;
      margin: 0px;
      margin-left: auto;
    }
  }
}