@import 'colors.scss';
@import 'fonts.scss';

.fbAdSetSummaryStep {
  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 40px 0 16px 30px;
  }

  .content {
    background-color: $white;
    padding: 30px;
    section {
      min-width: 300px;
      position: relative;
    }
    .categoryTitle {
      @include base-font;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.18;
      color: $dark;
      margin: 10px 0 10px 0;
      display: flex;
      align-items: center;

      .backBtn  {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: #5845a5;
        cursor: pointer;
        margin-left: 30px;
      }
    }

    .sepLine {
      height: 1px;
      border-top: solid 1px $super-light;
    }
  }

  .buttonArea {
    margin: 60px 0px 84px 30px;
    button {
      margin-right: 20px;
    }
  }
}