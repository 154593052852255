
@import '../../fonts.scss';
@import '../../colors.scss';

.orderListContainer {
  background-color: $white2;
  display: flex;
  flex-direction: column;
}

.orderList {
  flex-grow: 1;
  background-color: $white2;

  td {
    vertical-align: middle;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  th {
    outline: none;
    height: 46px;
  }

  .orderCellBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .orderCell {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nameCell {
    flex-direction: column;
    align-items: flex-start;
    .name {
      @include base-font;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }

    .orderNumberCell {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding-top: 4px;
    }
  }

  .detailBtn {
    margin-left: 10px;
    visibility: hidden;
    cursor: pointer;
    color: $dark;
  }

  table {
    margin-bottom: 0;
  }

  thead th, td {

    &.orderNumber {
      min-width: 274px;
      max-width: 274px;
      width: 274px;
      padding-left: 64px;
      padding-right: 15px;
      word-break: break-all;
      user-select: text;
    }

    &.advertiser {
      min-width: 200px;
      max-width: 200px;
      width: 200px;
      padding-left: 10px;
      padding-right: 15px;
      word-break: break-all;
    }

    &.externalId {
      min-width: 120px;
      padding-left: 10px;
      padding-right: 15px;
    }

    &.state {
      min-width: 90px;
      padding-left: 10px;
      padding-right: 15px;
    }

    &.endDate {
      min-width: 90px;
      padding-left: 10px;
      padding-right: 15px;
    }

    &.remainingDay {
      min-width: 100px;
      white-space: nowrap;
      padding-left: 10px;
      padding-right: 15px;
    }

    &.progress {
      min-width: 170px;
      max-width: 170px;
      width: 170px;
      padding-left: 10px;
      padding-right: 15px;
    }

    &.targetBudget {
      min-width: 100px;
      padding-left: 10px;
      padding-right: 15px;
    }

    &.spent {
      min-width: 100px;
      padding-left: 10px;
      padding-right: 15px;
    }

    &.editBtns {
      width: 0;
      padding: 0px;
    }
  }

  thead th {
    user-select: none !important;
    
    &.remainingDay {
      padding-right: 0px;
    }
  }

  .danger {
    color: $danger;
  }

  .floatingEditArea {
    display: none;
  }

  .budgetCell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .budget {
      padding-left: 5px;
    }
    .remainBudgetHint {
      white-space: nowrap;
      height: 18px;
      border-radius: 12px;
      background-color: $danger;
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: $white;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .progressCell {
    display: flex;
    flex-wrap: wrap;
    .progress {
      height: 18px;
      border-radius: 12px;
      @include base-font;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: $white;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 4px;
      margin-bottom: 4px;
      margin-right: 6px;
      &.notStart {
        background-color: $light;
      }
      &.normal {
        background-color: $success;
      }
      &.severelyBehind {
        background-color: $deep-warning;
      }
      &.behind {
        background-color: $warning;
      }
      &.ahead {
        background-color: $danger;
      }
    }
  }
  :global(.table-bordered) {
    tbody {
      .lightDanger {
        background-color: $light-danger;
        .orderNumber {
          background-color: $light-danger !important;
        }
        &:hover {
          background-color: $white2;
          .orderNumber {
            background-color: $white2 !important;
          }
        }
      }
      tr:hover {
        .floatingEditArea {
          display: flex;
        }
        .detailBtn {
          visibility: visible;
        }
      }
    }
  }
}

.operationArea {
  position: relative;
  display: flex;
  align-items: center;
  height: 66px;
  flex-shrink: 0;
  background-color: $white;
  > a {
    @include base-font;
    margin-left: 30px;
    height: 34px;
    border-radius: 2px;
    background-color: $theme1;
    color: $white;
    padding: 10px 30px;
    display: flex;
    align-items: center;
  }
  :global(.search-bar) {
    width: 280px;
    margin: 0px;
    position: absolute;
    right: 40px;
  }
}

.creatorInfo {
  position: absolute;
  left: 200px;
  display: flex;
  flex-direction: column;
  min-width: max-content;
  padding: 10px 10px 15px 10px;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 2px 10px 0 #98969d;
  z-index: 3;

  .closeBtn {
    width: 100%;
    padding-bottom: 5px;
    svg {
      color: $dark;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: $light;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;

    .title {
      display: flex;
      justify-content: space-between;
      width: 100px;
      &::after {
        content: ':';
      }
    }
    .content {
      padding: 0px 5px;
    }
  }
}
