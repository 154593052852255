@import '../../fonts.scss';
@import '../../colors.scss';
.creativeOverviewStateContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    .operationArea,
    .batchOperationArea {
        flex-shrink: 0;
        height: 65px;
        border-bottom-color: $super-light;
        background-color: $white;
        display: flex;
        align-items: center;
        padding-left: 30px;
         :global(.react-tagsinput) {
            border: none;
            padding: 0;
            min-height: 0;
            span {
                margin: 0;
            }
        }
         :global(.react-tagsinput-tag) {
            border: none;
            padding-top: 3px;
            a {
                top: 1px;
            }
        }
         :global(.react-tagsinput-input) {
            display: none;
        }
        .activateBtn,
        .deactivateBtn {
            @include base-font;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            text-align: center;
            color: $white;
            cursor: pointer;
        }
        .activateBtn {
            margin-left: 30px;
            margin-right: 38px;
        }
        button {
            height: 34px;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            text-align: center;
            @include base-font;
        }
        .bind {
            color: $white;
            border: none;
            margin-right: 20px;
        }
        .new {
            border: 1px solid $dark;
            background-color: $white;
            color: $dark;
        }
    }
    .batchOperationArea {
        background-color: $theme1;
    }
    .creatives {
        height: calc(100% - 65px);
    }
    .disabled-create:hover {
        cursor: not-allowed;
    }
    .disabled-create:active {
        pointer-events: none;
        cursor: not-allowed;
    }
    .tooltip {
        display: none;
        position: absolute;
        top: 2px;
        z-index: 2;
        height: 15px;
        font-size: 12px;
        font-weight: 500;
        @include base-font;
        line-height: 1.17;
        color: $dark;
        background-color: $white;
        padding: 0 5px;
        white-space: nowrap;
    }
    .block {
        &:hover {
            cursor: not-allowed;
            .tooltip {
                display: block;
            }
        }
    }
}