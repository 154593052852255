@import 'fonts.scss';

.previewAdTitle {
  padding: 0 9px;
  display: flex;
  align-items: center;
  div {
    font-weight: bold;
    word-break: break-word;
    text-align: justify;
    line-height: 1.4286em;
    font-family: Roboto, sans-serif;
    color: $black;
    overflow: hidden;
  }
}

.previewAdDescription {
  padding: 0px 9px;
  div {
    line-height: 1.4286em;
    font-family: Roboto, sans-serif;
    color: #888888;
    word-break: break-word;
    overflow: hidden;
  }
}

.previewSponsored {
  font-family: Roboto, sans-serif;
  color: #aaaaaa;
  padding: 3px 9px;
  overflow: hidden;
  display: flex;
  align-items: center;
  a {
    padding-left: 2px;
    font-size: 8pt;
    color: #428bca !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    padding-bottom: 1px;
  }
}

.showPointerCursor {
  cursor: pointer;
}

.wideNativePreview {
  width: 317px;
  .nativePreviewImg {
    width: 317px;
    height: 166px;
    box-sizing: border-box;
    border: 1px solid $super-light;
    background-color: $white;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .nativePreviewInfo {
    padding-top: 6px;
    width: 317px;
    border: 1px solid $super-light;
    border-top: none;
    box-sizing: border-box;
    .previewAdTitle {
      min-height: 40px;
      margin-bottom: 3px;
      div {
        max-height: 4.2858em;
        font-size: 18px;
      }
    }

    .previewAdDescription {
      div {
        font-size: 8pt;
        min-height: 30px;
        max-height: 102px;
        line-height: 17px;
      }
    }

    .previewSponsored {
      font-size: 8pt;
      padding-right: 2px;
      a {
        max-width: 280px;
      }
    }
  }
}

.squareNativePreview {
  display: flex;
  .nativePreviewImg {
    min-width: 128px;
    min-height: 128px;
    max-width: 128px;
    max-height: 128px;
    box-sizing: border-box;
    border: 1px solid $super-light;
    background-color: $white;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .nativePreviewInfo {
    width: 469px;
    height: 128px;
    border: 1px solid $super-light;
    border-left: none;
    box-sizing: border-box;
    .previewAdTitle {
      min-height: 54px;
      padding: 0px 5px;
      div {
        margin: 5px 0px;
        line-height: 22px;
        font-size: 19px;
        max-height: 44px;
      }
    }

    .previewAdDescription {
      display: flex;
      align-items: center;
      min-height: 51px;
      padding: 0px 5px;
      div {
        font-size: 9pt;
        max-height: 51px;
        line-height: 17px;
      }
    }

    .previewSponsored {
      font-size: 8pt;
      padding: 3px 5px;
      a {
        max-width: 350px;
      }
    }
  }
}

label[for='rectImg'],
label[for='squareImg'] {
  pointer-events: none;
}
