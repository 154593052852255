@import 'colors.scss';
@import 'fonts.scss';

.imgPreview {
  width: 100%;
  height: 100%;
  .imgPreviewArea {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.withBorder {
      border: 1px solid $super-light;
      box-sizing: border-box;
      background-color: $white;
    }
    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .imageName {
    @include base-font;
    min-height: 22px;
    font-size: 14px;
    line-height: 1.57;
    color: $black;
    margin-top: 5px;
  }
  .imageSize {
    @include base-font;
    height: 14px;
    font-size: 12px;
    line-height: 1.17;
    color: $dark;
  }
}