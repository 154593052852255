@import 'colors';
@import 'components';

.container {
  background-color: $white2;
  > header {
    @include panel($white);
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 0;
    position: relative;
    z-index: 3;
  }
}

.innerContent {
  @include tabs;

  :global(.nav) {
    padding-top: 30px;
    background-color: $white;
  }
  > form {
    fieldset {
      margin-top: 40px;
      padding-left: 0;
      padding-right: 0;
    }
    legend > span {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      color: $black;
      margin-left: 30px;
    }
    @include tab-form-content;
  }

  button {
    color: #fff;
  }

  .buttonArea {
    button,
    a {
      margin-right: 20px;
    }
    margin: 60px 0 120px 30px;
  }

  .logoInput {
    width: 150px;
    height: 150px;
  }
}
