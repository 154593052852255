@import 'fonts.scss';

.advertiserMemberListContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .operationArea {
        background-color: $white;
        border-bottom: 1px solid $super-light;
        position: relative;
        display: flex;
        align-items: center;
        height: 66px;
        flex-shrink: 0;
        button {
            @include base-font;
            margin-left: 30px;
            height: 34px;
            border-radius: 2px;
            padding: 10px 30px;
            display: flex;
            align-items: center;
            border-color: transparent;
        }
        :global {
            .btn-primary:hover {
                background-color: lighten($theme1, 15%);
            }
            .btn-primary:active,
            .btn-primary:not(:disabled):not(.disabled):active {
                background-color: darken($theme1, 15%);
            }
        }
        :global(.search-bar) {
            width: 280px;
            margin: 0px;
            position: absolute;
            right: 40px;
        }
    }
    .advertiserMemberList {
        td, th {
            vertical-align: middle;
        }
        flex-grow: 1;
        background-color: $white2;
    }
    .emailColumn {
        min-width: 300px;
        width: 30%;
        padding-right: 24px;
    }
    .statusColumn {
        width: fit-content;
        min-width: 100px;
        padding-right: 24px;
    }
    .nameIdColumn {
        min-width: 300px;
        width: 30%;
        padding-left: 64px;
    }
    .permissionColumn {
        min-width: 250px;
        padding-right: 24px;
    }
    .editBtnsColumn {
        width: 0px;
        padding: 0px;
    }
    .floatingEditArea {
        display: none;
    }
    tr:hover {
        .floatingEditArea {
            display: flex;
        }
    }
    .nameCell {
        cursor: pointer;
        color: $theme1;
        .name {
            @include base-font;
            width: 172px;
            font-size: 14px;
            line-height: 1.57;
            color: $theme1;
            padding: 0;
        }
        .id {
            @include base-font;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.2;
            color: $dark;
            padding: 4px 0px;
        }
    }
}