@import 'fonts.scss';
@import 'components.scss';

.doctorTable {
  @include tabs;
  td {
    vertical-align: middle;
  }
  :global(.nav) {
    flex-shrink: 0;
    padding-top: 28px;
  }
  :global(.reset-expansion-style) {
    cursor: default;
    background-color: $white !important;
  }
  .domain {
    max-width: 30%;
    min-width: 30%;
    width: 30%;
  }
  .filterArea {
    :global(.search-bar) {
      width: 280px;
    }
    padding-left: 40px;
    z-index: 1;
    height: 77px;
    width: 100%;
    border: 1px solid $super-light;
    border-left: none;
    border-right: none;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .customFilters {
      display: flex;
    }
    > div {
      padding-left: 20px; 
    }
    :global(.react-select__menu) {
      z-index: 3;
    }
    .filterListContainer {
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      .filterList.filterCondition {
        width: 150px;
      }
      .filterList.creative {
        width: 270px;
        margin-right: 20px;
      }
      .title {
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $dark;
        margin-right: 10px;
      }
    }
  }
}