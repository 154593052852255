@import 'colors.scss';

.retailMax {
  width: 100%;
  height: 100%;
  background-color: $white2;
  overflow-x: auto;

  .container {
    width: 100%;
    height: 100%;
  }
}