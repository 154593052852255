@import 'fonts.scss';

.hint {
  display: block;
  color: $warning;
  @include base-font;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  margin-top: 2px;
  width: 424px;
}