@import 'colors.scss';
@import 'fonts.scss';

.setupStep {
  min-width: min-content;
  height: 100%;
  overflow: auto;
  
  .ageAndGenderHint {
    @include base-font;
    color: $dark;
    flex: 1 1;
    a {
      font-size: inherit;
    }
  }

  :global(.nav-item.nav-link.active) {
    background-color: $white3;
    color: $theme1;
  }

  :global(.nav-item.nav-link) {
    height: 38px;
    color: $dark;
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }

  :global(.nav-item.nav-link:hover:not(.active)) {
    box-sizing: border-box;
    border-bottom-color: transparent;
    height: 100%;
  }

  :global(.nav-tabs) {
    padding-top: 27px;
    padding-left: 28px;
    height: 64px;
  }

  :global(.tab-content fieldset) {
    margin-top: 40px;
    padding-left: 0;
    padding-right: 0;
  }

  .buttonArea {
    margin: 60px 0 120px 30px;
    button {
      margin-right: 20px;
    }
  }

  .ageSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      width: 75px;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .optRadio {
    :global(.form-check) {
      padding-left: 0px;
    }
  }

  .ageGroupsHint {
    display: block;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #98969d;
    margin-top: 2px;
    width: 100%;
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }  
}