@import 'fonts.scss';

.container {
  .title {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    margin: 40px 0 16px 30px;
  }

  .content {
    background-color: $white;
    padding: 30px;
    .sepLine {
      height: 1px;
      border-top: solid 1px $super-light;
    }
  }

  .buttonArea {
    margin: 60px 0px 84px 30px;
    button {
      margin-right: 20px;
    }
  }

  .fbPageOption {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
  .fbPageOptionIcon {
    width: 23px;
    height: 23px;
    margin-right: 7px;
  }
}