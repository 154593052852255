@import 'fonts.scss';
@import 'colors.scss';
.advertiserDetailContainer {
  background-color: $white2;
  min-height: 100%;
  height: 1px;
  display: flex;
  flex-direction: column;
  .title {
    flex-shrink: 0;
    background-color: $white;
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
  }
  .titleBottomLine {
    width: 100%;
    height: 2px;
    border: 1px solid $super-light;
    background-color: $white;
  }
  .topArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    .buttonArea {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 30px;
      flex-shrink: 0;
      &>div, &>a {
        cursor: pointer;
        margin-left: 30px;
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        color: $theme1;
      }
    }
  }
  :global(.nav) {
    padding-left: 30px;
    background-color: $white;
    border-bottom: 1px solid $super-light;
  }
  .other {
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    margin-top: 28px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    background-color: $white3;
  }
  section {
    border-top: 1px solid $super-light;
    display: flex;
    flex-wrap: wrap;
  }

  .advertiserName {
    @include base-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    color: $black;
    padding: 20px 0px 11px 0px;
  }
  .field {
    flex-grow: 0;
    min-width: 20%;
    padding: 0;
    padding-right: 30px;
    margin: 10px 0;
  }
  .fieldLabel,
  .fieldValue {
    margin: 0;
    padding: 0;
    max-width: none;
    label {
      @include base-font;
      margin: 0;
      font-weight: 500;
      line-height: 1.17;
    }
  }
  .fieldLabel {
    font-size: 12px;
    color: $light;
  }
  .fieldValue {
    font-size: 14px;
    color: $dark;
    line-height: 1.57;
    margin-top: 2px;
    word-break: break-all;
  }

  .advertiserDetail {
    padding: 0px 30px 5px;
    margin-bottom: 28px;
    background-color: $white;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
  }

  .fbPageOption {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
  .fbPageOptionIcon {
    width: 23px;
    height: 23px;
    margin-right: 7px;
  }
}