@import 'fonts';

.fbPageOption {
  display: flex;
  align-items: center;
  img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
  .fbPageOptionDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .fbPageName {
      @include base-font;
      font-size: 12px;
      color: $black;
      height: 16px;
    }
    .fbPageId {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      color: $dark;
    }
  }
}

.fbPageSingleValue {
  display: flex;
  align-items: center;
  img {
    width: 23px;
    height: 23px;
    margin-right: 7px;
  }
  .fbPageName {
    @include base-font;
    font-size: 12px;
    color: $black;
    line-height: 14px;
  }
  .fbPageId {
    @include base-font;
    font-size: 10px;
    font-weight: 500;
    color: $dark;
    line-height: 12px;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
  &.fbPageOption {
    padding-left: 12px;
    opacity: 0.6;
    .fbPageId {
      height: 12px;
    }
    .fbPageOptionHint {
      @include base-font;
      font-size: 10px;
      color: $danger;
    }
  }
}