@import 'fonts.scss';

.draftL1ObjectOption {
  display: flex;
  align-items: center;
  width: 100%;
  .l1ObjectName {
    @include base-font;
    color: $black;
    flex-grow: 1;
  }
  &.disabled {
    padding: 8px 13px;
    .l1ObjectName {
      color: $super-light;
    }
  }

  .selected {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    color: $super-light;
    svg {
      margin-right: 6px;
    }
  }
}