.reportGran {
  display: flex;
  flex-direction: column;
  > div {
    width: fit-content;
  }
  > div:first-child {
    margin-bottom: 10px;
  }

  :global(.form-group) {
    margin-bottom: 0;
  }
}

.reportSearchBtnContainer {
  flex: 0 0 auto;
  padding-bottom: 30px;
  margin-bottom: 0;
}