@import 'fonts.scss';

.content {
  padding: 30px;
  .radioRow {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  ul {
    padding-top: 5px;
    padding-left: 20px;
    li {
      @include base-font;
      padding: 3px 0px;
      color: $dark;
    }
  }
}
.modalHint {
  margin-bottom: 10px;
}
.campaignUsageInfo {
  margin-top: 10px;
}