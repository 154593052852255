@import 'fonts.scss';

.advertiserListContainer {
  display: flex;
  flex-direction: column;
  td {
    vertical-align: middle;
  }
  .operationArea {
    flex-shrink: 0;
    background-color: $white;
    border-bottom: 1px solid $super-light;
    position: relative;
    display: flex;
    align-items: center;
    height: 66px;
    a {
      @include base-font;
      margin-left: 30px;
      height: 34px;
      border-radius: 2px;
      padding: 10px 30px;
      display: flex;
      align-items: center;
    }
    :global(.search-bar) {
      width: 280px;
      margin: 0px;
      position: absolute;
      right: 40px;
    }
  }
  .advertiserList {
    flex-grow: 1;
    background-color: $white2;
  }
  .nameCell {
    .name {
      @include base-font;
      width: 172px;
      font-size: 14px;
      line-height: 1.57;
      color: $theme1;
    }
    .id {
      @include base-font;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.2;
      color: $dark;
      padding: 4px 0px;
    }
  }
  .id {
    min-width: 300px;
    max-width: 300px;
    width: 300px;
    padding-left: 64px;
    padding-right: 24px;
    word-break: break-all;
  }
  .category {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    padding-right: 24px;
  }
  .website {
    min-width: 250px;
    padding-right: 24px;
    word-break: break-all;
  }
  .editBtns {
    min-width: 0;
    max-width: 0;
    width: 0;
    padding: 0px;
    .floatingEditBtnsCell {
      display: none;
    }
  }
  tr:hover {
    .editBtns .floatingEditBtnsCell {
      display: flex;
    }
  }
}