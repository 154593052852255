@import 'fonts.scss';

.multiSelectCheckbox {
  width: max-content;
  min-width: 200px;
  background-color: $white;
  border: 1px solid rgba(36, 35, 40, 0.2);
  border-radius: 0.3rem;
  .title {
    @include base-font;
    font-size: 12px;
    line-height: 1.17;
    color: $dark;
    margin: 12px;
  }
  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px 12px;
    li {
      @include base-font;
      line-height: 1.57;
      padding: 6px 0px;
      label {
        color: $dark;
      }
    }
  }
  .buttonArea {
    height: 42px;
    border-top: 1px solid $super-light;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .miniButton {
      height: 24px;
      padding: 5px 10px;
      border-radius: 2px;
      font-size: 12px;
      border: 1px solid $super-light;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
}
