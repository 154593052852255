@import '../../fonts.scss';
@import '../../colors.scss';

.app-menu-item {

  color: $dark;
  height: 42px;
  overflow: hidden;
  width: max-content;
  min-width: 100%;

  a {
    @include medium;
    @include body-font;
    color: $dark;
    line-height: 42px;
    display: block;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  a:visited {
    color: inherit
  }

  span {
    padding: 0px 45px;
  }

  &.now {
    background-color: $white;
    a {
      color: $theme1;
    }
  }
}


.app-menu-item:hover {
  background-color: $white;
}