.imageCreativeDetailContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .imageCreativeDetail {
    width: max-content;
    height: max-content;
    cursor: pointer;
  }
}