@import 'colors.scss';

.main-page {

  height: 100%;
  display: flex;
  flex-direction: column;

  .main-page-container {
    margin-top: 50px;
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;

    .content {
      flex-grow: 1;
      width: calc(100% - 200px);
      ::-webkit-scrollbar {
        width: 0px;
        height: 10px;
      }
      ::-webkit-scrollbar-thumb {
          background: #c2c1c1; 
          border-radius: 4px;
      }
      ::-webkit-scrollbar-track {
        border-top: 1px solid $super-light;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: $light;
      }
    }
  }
}
