@import 'colors.scss';

.account-actors {
  display: flex;
  tr {
    .actions {
      display: none;
    }
    
    &:hover {
      .actions {
        display: flex;
      }
    }
  }
  .company-name {
    padding-left: 64px;
    width: 40%;
    max-width: 500px;
    overflow-wrap: break-word;
  }
  .actions-injectable {
    display: flex;
    flex-direction: row;

    .actions {
      flex-direction: row-reverse;
      margin: auto 10px auto auto;

      .action {
        cursor: pointer;
        padding: 3px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        line-height: 12px;
        color: $dark;

        svg {
          margin: 0;
        }
      }
    }
  }
}
