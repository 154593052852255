@import 'colors.scss';

.tableRowToolBar {
  display: flex;
  align-items: center;
  padding-right: 34px;
  height: 18px;
  box-shadow: 0 1px 3px 0 rgba(97, 95, 103, 0.5);
  background-color: $white;
  position: absolute;
  top: 0;
  right: 0;
  .toolBarBtn {
    margin: 0 8px;
    cursor: pointer;
  }
}