@import '../../../colors.scss';

.loading-indicator {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1070;
  background-color: rgba(97, 95, 103, 0.5);
  display: flex;

  .board {
    margin: auto;
    max-width: 50%;
    max-height: 50%;
    width: 500px;
    height: 200px;
    border-radius: 5px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 20px 0 #98969d;

    .roller {
      margin: 50px auto auto auto;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      border: 5px solid $theme1;
      animation-name: roller;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      border-left-color: transparent !important;
    }

    @keyframes roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .message {
      margin: auto auto 50px auto;
    }
  }
}
