@import 'fonts.scss';
@import 'components.scss';

.productSegmentList {
  $topAreaHeight: 77px;
  height: 100%;
  background-color: $white3;
  display: flex;
  flex-direction: column;
  .titleArea {
    @include base-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: $black;
    padding: 30px;
    box-shadow: 2px 2px 5px 0 rgba(97, 95, 103, 0.5);
    background-color: $white;
    margin-bottom: 30px;
    .filterArea {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .statusList, .retailerList, .advertiserList {
      flex-shrink: 0;
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      margin-right: 30px;
      .title {
        display: flex;
        align-items: center;
        white-space: nowrap;
        @include base-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        color: $dark;
        margin-right: 10px;
      }
    }
    .advertiserList {
      z-index: 5;
    }
    .retailerList, .statusList {
      width: 300px;
    }
  }
  .operationArea {
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $topAreaHeight;
    background-color: $white;
    position: relative;
    a {
      @include base-font;
      margin-left: 30px;
      height: 34px;
      border-radius: 2px;
      color: $white;
      padding: 10px 30px;
      display: flex;
      align-items: center;
    }
    :global(.search-bar) {
      width: 280px;
    }
  }
  .contentArea {
    flex-grow: 1;
    background-color: $white2;
  }
  th:first-child,
  td:first-child {
    padding-left: 64px;
  }
  .floatingEditArea {
    display: none;
  }
  tr:hover {
    .floatingEditArea {
      display: flex;
    }
  }
}

.archiveProductSegmentModal {
  .modalHint {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  :global(.modal-header) {
    display: none;
  }
}